const manufacturing = [
  {
    path: '/manufacturing/dashboard',
    name: 'goals-dashboard',
    component: () => import('@/views/manufacturing/dashboard/dashboard.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },
  {
    path: '/manufacturing/mbom-list',
    name: 'mbom-list',
    component: () => import('@/views/manufacturing/mbom/List.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },
  {
    path: '/manufacturing/mbom/view/:uuid',
    name: 'mbom-details',
    component: () => import('@/views/manufacturing/mbom/Details.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },
  {
    path: '/manufacturing/mbom/create',
    name: 'mbom-create',
    component: () => import('@/views/manufacturing/mbom/Create.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },
  {
    path: '/manufacturing/mbom/edit/:uuid',
    name: 'mbom-edit',
    component: () => import('@/views/manufacturing/mbom/Create.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },
  

  {
    path: '/manufacturing/bill-of-materials',
    name: 'bill-of-materials',
    component: () => import('@/views/manufacturing/bill-of-materials/List.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },
  {
    path: '/manufacturing/bill-of-materials/view/:uuid',
    name: 'bill-of-materials-details',
    component: () => import('@/views/manufacturing/bill-of-materials/Details.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },
  {
    path: '/manufacturing/bill-of-materials/create',
    name: 'create-bill-of-materials',
    component: () => import('@/views/manufacturing/bill-of-materials/Create.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },
  {
    path: '/manufacturing/bill-of-materials/edit/:uuid',
    name: 'bom-edit',
    component: () => import('@/views/manufacturing/bill-of-materials/Create.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },


  {
    path: '/manufacturing/work-centers',
    name: 'work-centers',
    component: () => import('@/views/manufacturing/work-centers/List.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },
  {
    path: '/manufacturing/work-centers/view/:uuid',
    name: 'work-centers-details',
    component: () => import('@/views/manufacturing/work-centers/Details.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },
  {
    path: '/manufacturing/work-centers/create',
    name: 'create-work-centers',
    component: () => import('@/views/manufacturing/work-centers/Create.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },
  {
    path: '/manufacturing/work-centers/edit/:uuid',
    name: 'work-centers-edit',
    component: () => import('@/views/manufacturing/work-centers/Create.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },



  {
    path: '/manufacturing/manufacturing-order',
    name: 'manufacturing-order',
    component: () => import('@/views/manufacturing/manufacturing-order/List.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },
  {
    path: '/manufacturing/manufacturing-order/view/:uuid',
    name: 'manufacturing-order-details',
    component: () => import('@/views/manufacturing/manufacturing-order/Details.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },
  {
    path: '/manufacturing/manufacturing-order/create',
    name: 'create-manufacturing-order',
    component: () => import('@/views/manufacturing/manufacturing-order/Create.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },
  {
    path: '/manufacturing/manufacturing-order/edit/:uuid',
    name: 'manufacturing-order-edit',
    component: () => import('@/views/manufacturing/manufacturing-order/Create.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },


  {
    path: '/manufacturing/manufacturing-planning',
    name: 'manufacturing-planning',
    component: () => import('@/views/manufacturing/manufacturing-planning/List.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },
  {
    path: '/manufacturing/manufacturing-planning/:uuid',
    name: 'manufacturing-planning-details',
    component: () => import('@/views/manufacturing/manufacturing-planning/Details.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },
  {
    path: '/manufacturing/manufacturing-planning/create',
    name: 'create-manufacturing-planning',
    component: () => import('@/views/manufacturing/manufacturing-planning/Create.vue'),
    meta: {
      layout: 'content',
      app: 'manufacturing',
      appId: 16,
    },
  },

]

export default manufacturing
