<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    :nudge-left="100"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        readonly
        :data-cy="label"
        :placeholder="label"
        outlined
        hide-details="auto"
        :rules="[...rules]"
        class="min-width"
        dense
        solo
        flat
        v-bind="attrs"
        v-on="on"
        :append-icon="icons.mdiCalendarMonth"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title range :allowed-dates="allowedDates">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="onChangeDate"> OK </v-btn>
    </v-date-picker>
  </v-menu>
</template>
<script>
import { mdiCalendarMonth } from '@mdi/js'
import { required } from '@core/utils/validation'
import moment from 'moment'

export default {
  props: {
    label: {
      type: String,
      default: 'Date Range',
    },
    styleType: {
      type: String,
      default: 'outlined',
    },
    startDate: {
      type: String,
      default: null,
    },
    endDate: {
      type: String,
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    isAllowedPastDate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menu: false,
      date: [this.startDate, this.endDate],
    }
  },
  computed: {
    dateRangeText: {
      get() {
        if (this.date.includes(null)) {
          return []
        }

        if (Date.parse(this.date[1]) < Date.parse(this.date[0])) {
          const temp = this.date[1]
          const temp2 = this.date[0]
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.date[1] = temp2
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.date[0] = temp
        }

        return this.date.join(' ~ ')
      },
      set() {
        this.date = []
      },
    },
  },
  watch: {
    startDate(date) {
      this.date = [date, this.endDate]
    },
  },
  methods: {
    onChangeDate() {
      if (this.date && this.date.length === 2) {
        this.$refs.menu.save(this.date)
        this.menu = false
        this.$emit('onSelect', this.date)
      }
    },
  },
  setup(props) {
    const allowedDates = val => {
      if (props.isAllowedPastDate) return true
      if (moment().isSame(val, 'day')) {
        return true
      }

      return moment(val, 'YYYY-MM-DD').isAfter(moment())
    }

    return {
      allowedDates,
      required,
      icons: {
        mdiCalendarMonth,
      },
    }
  },
}
</script>
