// eslint-disable-next-line object-curly-newline
import {
  mdiAccountBoxOutline,
  mdiChartTimelineVariant,
  mdiHomeOutline,
} from '@mdi/js'

export default [
  {
    title: 'Customer Menu',
    icon: mdiHomeOutline,
    children: [
      {
        icon: mdiChartTimelineVariant,
        title: 'Account',
        to: 'crm-retail-account',
        moduleKey: 'account',
      },
      // {
      //   icon: mdiAccountBoxOutline,
      //   title: 'Contact',
      //   to: 'crm-contact',
      //   moduleKey: 'contact',
      // },

    ],
  },
]
