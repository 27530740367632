<template>
  <layout-content-horizontal-nav :nav-menu-items="[]">
    <!-- Default Slot -->
    <slot></slot>

    <!-- Navbar -->
    <template #navbar="{ launcherDrawer }">
      <div class="navbar-content-container">
        <!-- Left Content: Search -->
        <div class="d-flex align-center">
          <router-link to="/">
            <v-img
              :src="require('@/assets/images/svg/logoDark1.svg')"
              height="28"
              width="122"
            ></v-img>
          </router-link>
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center">
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
      <v-overlay
        :value="$store.state.app.shallContentShowOverlay"
        z-index="5"
        absolute
        class="system-bar-overlay"
      ></v-overlay>
    </template>
  </layout-content-horizontal-nav>
</template>

<script>
import LayoutContentHorizontalNav from '@core/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import AppBarUserMenu from '@core/layouts/partner/AppBarUserMenu.vue'
import { mdiHeartOutline, mdiFaceAgent, mdiTextBox } from '@mdi/js'

export default {
  components: {
    LayoutContentHorizontalNav,
    AppBarUserMenu,
  },
  props: {
    light: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

    return {
      // Search
      icons: {
        mdiTextBox,
        mdiHeartOutline,
        mdiFaceAgent,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}
</style>
