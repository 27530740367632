<template>
  <div>
    <vue-editor
      ref="quillEditor"
      v-model="commentFormData.comment"
      data-cy="quill-editor"
      :editor-options="editorSettings"
      :editor-toolbar="customToolbar"
      use-custom-image-handler
      @image-added="handleImage"
    ></vue-editor>
    <div class="text-right mt-2">
      <v-btn
        v-show="commentFormData.comment"
        outlined
        color="secondary"
        text
        small
        class="mr-2"
        @click="quillEditor.quill.setText(''), $emit('cancelComment')"
      >
        Clear
      </v-btn>
      <v-btn
        small
        data-cy="comment-btn"
        color="primary"
        :loading="commentFormData.loading"
        @click="addComment"
      >
        Comment
      </v-btn>
    </div>
  </div>
</template>

<script>
import { storeGetters } from '@/store/storeConstants'
import { VueEditor, Quill } from 'vue2-editor'
import { ref, computed } from '@vue/composition-api'
import quillMention from 'quill-mention'
import 'quill-mention/dist/quill.mention.css'
import accountService from '@/components/crm/account/accountService'
import useFileUploader from '@core/utils/useFileUploader'
import Toaster from '@core/utils/sweetToast'
import { useRouter } from '@core/utils'
import ImageResize from 'quill-image-resize-vue'
import QuillImageDropAndPaste from 'quill-image-drop-and-paste'
import { ERROR_RESPONSE } from '@/constants/appConstants'

Quill.register('modules/mention', quillMention)
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)

export default {
  components: {
    VueEditor,
  },
  props: {
    propType: {
      type: String,
      required: true,
    },
    propId: {
      type: String,
      required: true,
    },
    parentId: {
      type: String,
      default: undefined,
    },
    commentForm: {
      type: Object,
      default: () => {},
    },
    documentDirectory: {
      type: String,
      required: true,
    },
  },
  setup(props, { root, emit }) {
    const { GET_ALL_USER } = storeGetters
    const { uploadStep1, uploadStep2, uploadStep3 } = useFileUploader()
    const { route } = useRouter()

    const comment = ref(null)
    const loading = ref(false)
    const quillEditor = ref(null)
    const customToolbar = [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
    ]
    console.log(props.documentDirectory, 'documentDirectory2432423')
    console.log('time')
    const { fetchUsers } = accountService()
    fetchUsers()
    const userList = computed(() => root.$store.getters[`users/${GET_ALL_USER}`])

    const imageHandler = async (imageDataUrl, type, imageData) => {
      const file = imageData.toFile()
      const payload = {
        object: {
          prop_id: props.propId,
          prop_type: props.propType,
          type: 1,
          parent_id: props.documentDirectory,
          name: file.name,
        },
        component: 'workdrive/',
      }
      console.log(payload.object, 'payload')
      const { data } = await uploadStep1(payload)
      if (data && data.data) {
        // upload to s3
        const object = {
          component: data.data.url,
          file,
        }
        const response = await uploadStep2(object)
        if (response) {
          const resp = await uploadStep3(data.data, true)
          if (resp.data && resp.data.success) {
            let index = (quillEditor.value.quill.getSelection() || {}).index
            if (index === undefined || index < 0) index = quillEditor.value.quill.getLength()
            quillEditor.value.quill.insertEmbed(index, 'image', resp.data.data, 'user')
          } else {
            Toaster.error(ERROR_RESPONSE, 'warning')
          }
        }
      } else {
        Toaster.error(ERROR_RESPONSE, 'warning')
      }
    }

    const editorSettings = {
      modules: {
        mention: {
          allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
          mentionDenotationChars: ['@', '#'],
          async source(searchTerm, renderList) {
            if (!searchTerm) return
            await fetchUsers(searchTerm)
            renderList(userList.value)
          },
        },
        imageResize: {
          modules: ['Resize'],
        },
        imageDropAndPaste: {
          handler: imageHandler,
        },
      },
    }

    const commentFormData = computed(() => {
      return props.commentForm
    })

    const addComment = () => {
      if (!commentFormData.value.comment) return
      emit('commentEvent', () => {
        quillEditor.value.quill.setText('')
      })
    }
    const handleImage = async (file, Editor, cursorLocation, resetUploader) => {
      const payload = {
        object: {
          prop_id: props.propId,
          prop_type: props.propType,
          type: 1,
          parent_id: props.documentDirectory,
          name: file.name,
        },
        component: 'workdrive/',
      }
      const { data } = await uploadStep1(payload)
      if (data && data.data) {
        // upload to s3
        const object = {
          component: data.data.url,
          file,
        }
        const response = await uploadStep2(object)
        if (response) {
          const resp = await uploadStep3(data.data, true)
          if (resp.data && resp.data.success) {
            let pos = 0
            if (commentFormData.value.comment) {
              Editor.insertText(cursorLocation, '\n')
              pos = 1
            }
            // upload image uuid inserting from uploadStep1
            Editor.insertEmbed(cursorLocation + pos, 'image', resp.data.data)
            resetUploader()
          } else {
            Toaster.error(ERROR_RESPONSE, 'warning')
          }
        }
      } else {
        Toaster.error(ERROR_RESPONSE, 'warning')
      }
    }

    return {
      comment,
      quillEditor,
      customToolbar,
      editorSettings,
      userList,
      addComment,
      handleImage,
      loading,
      commentFormData,
    }
  },
}
</script>
<style lang="scss">
.theme--light.v-card > .v-card__text{
  color: #000000;
}
</style>
