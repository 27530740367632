import {
  mdiWarehouse,
  mdiSilo,
  mdiListStatus,
  mdiReceipt,
  mdiTruckDelivery,
  mdiNintendoSwitch,
  mdiVectorLink,
  mdiLogout,
  mdiLogin,
  mdiAdjust,
  mdiHomeLock,
  mdiHomeLockOpen,
  mdiNotebookRemove,
  mdiStore,
  mdiNumeric,
  mdiTimelineCheck,
  mdiClipboardClockOutline,
  mdiShoppingOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'Inventory Management',
  },
  {
    title: 'Warehouse',
    icon: mdiWarehouse,
    to: 'Warehouse',
    module: 'warehouse',
  },
  {
    title: 'Inventory',
    icon: mdiSilo,
    to: 'inventory-list',
    module: 'inventory',
  },
  {
    title: 'Purchase Order',
    icon: mdiListStatus,
    to: 'purchase-order',
    module: 'purchaseOrder',
  },
  {
    title: 'Change Order',
    icon: mdiListStatus,
    to: 'inv-change-order',
    module: 'changeOrder',
  },
  {
    title: 'Receipt',
    icon: mdiReceipt,
    to: 'receipts-list',
    module: 'receipt',
  },
  {
    title: 'Delivery',
    icon: mdiTruckDelivery,
    to: 'delivery-list',
    module: 'delivery',
  },
  // {
  //   title: 'Shop Pull',
  //   icon: mdiStorePlus,
  //   to: 'shop-pull-list',
  //   module: 'shopPull',
  // },
  {
    title: 'Shop Requisition',
    icon: mdiStore,
    to: 'shop-requition-list',
    module: 'shopPullRequisition',
  },
  {
    title: 'Issue without Requisition',
    icon: mdiHomeLock,
    to: 'without-requisition',
    module: 'requisitionWithoutIssue',
  },
  {
    title: 'Create Requisition',
    icon: mdiHomeLockOpen,
    to: 'requisition',
    module: 'requisition',
  },
  {
    title: 'Issue Against Requisition',
    icon: mdiNintendoSwitch,
    to: 'issue-against-requisition',
    module: 'issueAgainstRequisition',
  },
  {
    title: 'Internal Transfer',
    icon: mdiVectorLink,
    to: 'internal-transfer',
    module: 'internalTransfer',
  },
  {
    title: 'Sign Out',
    icon: mdiLogout,
    to: 'inventory-signout',
    module: 'signOut',
  },
  {
    title: 'Bring Back',
    icon: mdiLogin,
    to: 'bring-back',
    module: 'bringBack',
  },
  {
    title: 'Inventory Adjustment',
    icon: mdiAdjust,
    to: 'inventory-adjustment-list',
    module: 'inventoryAdjustment',
  },
  {
    title: 'Physical Count',
    icon: mdiNumeric,
    to: 'physical-count-list',
    module: 'physicalCount',
  },
  {
    title: 'Approve Physical Count',
    icon: mdiTimelineCheck,
    to: 'approve-physical-count-list',
    module: 'physicalCount',
  },
  {
    title: 'Inventory Movement',
    icon: mdiClipboardClockOutline,
    to: 'inventory-movement',
    module: 'inventoryMovement',
  },
  {
    title: 'Void',
    icon: mdiNotebookRemove,
    to: 'inventory-void',
    module: 'inventoryMovement',
  },
  {
    title: 'Stock Report',
    icon: mdiShoppingOutline,
    to: 'inventory-stock-report',
    module: 'inventoryMovement',
  },
]
