<template>
  <div class="d-flex flex-wrap">
    <v-chip
      v-for="item in taskInfo.parent"
      :key="item.uuid"
      small
      class="parent-chip py-2 font-weight-medium dark--text text-xs2 mr-1"
      label
      :close="!hasReadOnlyAccess && !taskInfo.space_fixed"
      @click:close="detachDependency(item)"
      @click="handleParentTask(item)"
    >
      <span> {{ item.name }}</span>
    </v-chip>

    <v-menu
      v-if="!hasReadOnlyAccess"
      v-model="isOpenMenu"
      offset-y
      :close-on-content-click="false"
      min-width="270"
      max-height="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs" color="dark" size="18" v-on="on">{{ mdiPlus }}</v-icon>
      </template>
      <v-list>
        <v-list-item class="mb-2">
          <v-text-field
            v-model="search"
            outlined
            dense
            hide-details
            class="filter-input"
            placeholder="Search projects, folder"
            @input="processInput"
          ></v-text-field>
        </v-list-item>
        <div style="max-height: calc(400px - 64px); overflow: auto">
          <v-list-item
            v-for="(item, index) in itemList"
            :key="index"
            class="parent-list"
            @click="handleItemAttach(item)"
          >
            <v-list-item-title class="dark--text d-flex">
              <v-icon v-if="item.type === 'project'" size="17" class="mr-2">
                {{ mdiCalendarTextOutline }}
              </v-icon>
              <v-icon v-else-if="item.type === 'folder'" size="17" class="mr-2">
                {{ mdiFolderOutline }}
              </v-icon>
              <v-icon v-else size="17" class="mr-2">{{ mdiFileOutline }}</v-icon>
              <div>
                <p class="my-0 text-xs font-weight-medium">{{ item.name }}</p>
                <div class="d-flex" style="font-weight: 300; font-size: 11px">
                  <div
                    v-for="(crump, i) in item.breadcrumb.slice(-4, -1)"
                    :key="i"
                    class="my-0 d-flex"
                  >
                    <p class="my-0 crump-name">{{ crump.name }}</p>
                    /
                  </div>
                </div>
              </div>
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import {
  mdiPlus,
  mdiClose,
  mdiFolderOutline,
  mdiCalendarTextOutline,
  mdiFileOutline,
} from '@mdi/js'
import { ref, computed } from '@vue/composition-api'
import store from '@/store'
import { storeActions } from '@/store/storeConstants'
import useDebounce from '@/plugins/useDebounce'
import { useRouter } from '@core/utils'
import { nanoid } from 'nanoid'

export default {
  props: {
    taskInfo: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { route } = useRouter()

    const itemList = ref([])
    const isOpenMenu = ref(false)
    const search = ref(undefined)
    const { debounce } = useDebounce()
    const record = computed(() => store.getters['tableView/getTasks'])

    const loadItemTask = () => {
      // load task
      const payload = {
        component: 'task-management/spaces/item/attach/list',
        object: {
          space_id: props.taskInfo.space_id,
          search: search.value,
        },
      }
      store.dispatch(`common/${storeActions.CREATE}`, payload).then(({ data }) => {
        if (data.success) {
          itemList.value = data.data
        }
      })
    }
    loadItemTask()

    const handleItemAttach = item => {
      const payload = {
        component: `task-management/spaces/item/${props.taskInfo.uuid}/attach/${item.uuid}`,
      }
      store.dispatch(`common/${storeActions.CREATE}`, payload).then(({ data }) => {
        isOpenMenu.value = false
        const routeName = route.value.name
        emit('fetchTask')
        if (routeName === 'table-task' || routeName === 'share-list') {
          // task to be moved or copy
          const taskData = structuredClone(props.taskInfo)
          delete taskData.parent
          // there is possibility folder or project may be multiple times in list so we need to use loop
          const findItems = record.value.filter(opt => {
            return opt.uuid === item.uuid
          })
          findItems.forEach(element => {
            taskData.id = nanoid()
            taskData.parent_id = element.id
            store.dispatch('tableView/UPDATE_SPECIFIC_TASK', {
              createdTask: taskData,
              taskId: element.id,
              type: 'add',
            })
          })
          // check item uuid match with router taskId than move item to root desitination
        }
      })
    }

    const processInput = () => {
      debounce(() => loadItemTask(), 800)
    }

    const detachDependency = item => {
      const payload = {
        component: `task-management/spaces/item/${props.taskInfo.uuid}/deattach`,
        objectId: item.uuid,
      }
      store.dispatch(`common/${storeActions.DELETE_BY_ID}`, payload).then(opt => {
        // on deleting dependency item should be remove from folder or project
        // whether project or foler is open or closed
        emit('fetchTask')
        const routeName = route.value.name
        if (routeName === 'table-task' || routeName === 'share-list') {
          // task to be removed
          const taskData = structuredClone(props.taskInfo)
          delete taskData.parent
          // there is possibility filer or project may be multiple times in list so we need to use loop
          const findItems = record.value.filter(elm => {
            return elm.uuid === item.uuid
          })
          findItems.forEach(element => {
            taskData.id = nanoid()
            taskData.parent_id = element.id
            store.dispatch('tableView/UPDATE_SPECIFIC_TASK', {
              createdTask: taskData,
              taskId: element.id,
              type: 'delete',
            })
          })
        }
      })
    }

    const hasReadOnlyAccess = computed(() => {
      if (!props.taskInfo?.permission) return false
      // 0 is no access 1 is read, 2 is editor, 3-4 is full or admin access
      if ([1, 0].includes(props.taskInfo.permission)) {
        return true
      }

      return false
    })

    const handleParentTask = item => {
      store.commit('taskManagement/SET_TASKBAR_ID', item.uuid)
    }

    return {
      itemList,
      search,
      isOpenMenu,
      processInput,
      detachDependency,
      handleItemAttach,
      hasReadOnlyAccess,
      handleParentTask,
      // icons
      mdiPlus,
      mdiClose,
      mdiFolderOutline,
      mdiCalendarTextOutline,
      mdiFileOutline,
    }
  },
}
</script>
<style scoped lang="scss">
::v-deep {
  .v-input.filter-input .v-input__control .v-input__slot {
    min-height: 30px !important;
  }

  // .parent-list.v-list-item {
  //   height: 2.3rem !important;
  //   min-height: 2.3rem !important;
  // }
}

.crump-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
}
</style>
