const partnerPortal = [
  {
    path: '/partner/signup',
    name: 'partner-signup',
    component: () => import('@/views/partner-portal/SignUp.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      app: 'partnerPortal',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/partner/email-confirmation',
    name: 'partner-email',
    component: () => import('@/views/partner-portal/SignUpEmailSent.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      app: 'partnerPortal',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/partner/login',
    name: 'partner-login',
    component: () => import('@/views/partner-portal/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      app: 'partnerPortal',

      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/partner/forgot-password',
    name: 'partner-forgot-password',
    component: () => import('@/views/partner-portal/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      app: 'partnerPortal',

      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/partner/resetPassword',
    name: 'partner-reset-password',
    component: () => import('@/views/partner-portal/ResetPassword.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      app: 'partnerPortal',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/partner/profile',
    name: 'partner-profile',
    component: () => import('@/views/partner-portal/PartnerProfile.vue'),
    meta: {
      layout: 'partner-horizontal',
      app: 'partnerPortal',
    },
  },
  {
    path: '/partner/dashboard',
    name: 'partner-dashboard',
    component: () => import('@/views/partner-portal/Dashboard.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
    },
  },
  {
    path: '/partner/partner-details',
    name: 'partner-details',
    component: () => import('@/views/partner-portal/PartnerDetails.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
    },
  },
  {
    path: '/partner/customer/verify',
    name: 'confirm-mail',
    component: () => import('@/components/partner-portal/customer/MailConfirmation.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
    },
  },

  {
    path: '/partner/customers',
    name: 'partner-customers',
    component: () => import('@/views/partner-portal/customer/CustomerList.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
    },
  },
  {
    path: '/partner/view/:uuid/:account_id',
    name: 'partner-view',
    component: () => import('@/views/partner-portal/customer/CustomerView.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
    },
  },
  {
    path: '/partner/partner-support',
    name: 'partner-support',
    component: () => import('@/views/partner-portal/partner-support/PartnerSupportList.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
    },
  },
  {
    path: '/partner/bldon-support',
    name: 'bldon-support',
    component: () => import('@/views/partner-portal/bldon-support/BldonSupportList.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
    },
  },
  {
    path: '/partner/bldon-support-ticket',
    name: 'bldon-support-ticket',
    component: () => import('@/views/partner-portal/bldon-support/BldonCreateTicket.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
    },
  },
  {
    path: '/partner/bldon-support-view/:uuid',
    name: 'bldon-support-view',
    component: () => import('@/views/partner-portal/bldon-support/BldonSupportview.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
    },
  },
  {
    path: '/partner/partner-support-view/:uuid',
    name: 'partner-support-view',
    component: () => import('@/views/partner-portal/partner-support/PartnerSupportView.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
    },
  },
  {
    path: '/partner/role-overview',
    name: 'partner-roles-overview',
    component: () => import('@/views/security/roles/RoleOverview.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
      // appId: 3,
    },
  },

  {
    path: '/partner/role-overview/:id',
    name: 'partner-edits-role',
    component: () => import('@/views/security/roles/RoleOverview.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
      // appId: 3,
    },
  },
  {
    path: '/partner/add-role',
    name: 'partner-add-role',
    component: () => import('@/components/partner-portal/partner-security/roles/AddRole.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
      // appId: 3,
      permission: 'policy',
      permissionType: 'a',
    },
  },
  {
    path: '/partner/edit-role/:id',
    name: 'partner-edit-role',
    component: () => import('@/components/partner-portal/partner-security/roles/AddRole.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
      // appId: 3,
      permission: 'policy',
      permissionType: 'e',
    },
  },
  {
    path: '/partner/group-view/:id',
    name: 'partner-group-view',
    component: () => import('@/components/partner-portal/partner-security/user-group/GroupView.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
      // appId: 3,
    },
  },

  {
    path: '/partner/user-overview',
    name: 'partner-user-overview',
    component: () => import('@/views/partner-portal/partner-security/UserOverview.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
      // appId: 3,
      permission: 'users',
      permissionType: 'a',
    },
  },
  {
    path: '/partner/user-overview/:userId',
    name: 'partner-edit-user',
    component: () => import('@/views/partner-portal/partner-security/UserOverview.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
      // appId: 3,
      permission: 'users',
      permissionType: 'e',
    },
  },
  {
    path: '/partner/security/',
    name: 'partner-security',
    component: () => import('@/views/partner-portal/partner-security/PartnerSecurity.vue'),
    meta: {
      layout: 'partner',
      app: 'partnerPortal',
    },
    redirect: {
      name: 'partner-user',
    },
    children: [
      {
        path: 'user-management',
        name: 'partner-user',
        component: () => import('@/components/partner-portal/partner-security/UserManagement.vue'),
        meta: {
          layout: 'partner',
          app: 'partnerPortal',
          // appId: 3,
        },
      },
      {
        path: 'user-group',
        name: 'partner-usergroup',
        component: () => import('@/components/partner-portal/partner-security/user-group/UserGroup.vue'),
        meta: {
          layout: 'partner',
          app: 'partnerPortal',
          // appId: 3,
        },
      },
      {
        path: 'roles',
        name: 'partner-roles',
        component: () => import('@/components/partner-portal/partner-security/roles/Roles.vue'),
        meta: {
          layout: 'partner',
          app: 'partnerPortal',
          // appId: 3,
        },
      },
    ],
  },
]

export default partnerPortal
