import { render, staticRenderFns } from "./FileAndSubtemSection.vue?vue&type=template&id=a7f5ca5e&scoped=true&"
import script from "./FileAndSubtemSection.vue?vue&type=script&lang=js&"
export * from "./FileAndSubtemSection.vue?vue&type=script&lang=js&"
import style0 from "./FileAndSubtemSection.vue?vue&type=style&index=0&id=a7f5ca5e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7f5ca5e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VChip,VFileInput,VIcon,VList,VListItem,VListItemTitle,VMenu,VTextField,VTextarea})
