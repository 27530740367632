export default {
  namespaced: true,
  state: {
    cartItem: [],
    customer: null,
    selectCartId: null,
    inventoryStore: [],
    registryDetail: {},
  },
  getters: {
    getCartItem: state => state.cartItem,
    getCustomer: state => state.customer,
    getSelectCartId: state => state.selectCartId,
    getInventoryStore: state => state.inventoryStore,
    getRegistryDetail: state => state.registryDetail,
  },
  mutations: {
    SET_CART_ITEM(state, payload) {
      state.cartItem = payload
    },
    SET_CUSTOMER(state, payload) {
      state.customer = payload
    },
    PUSH_CART_ITEM(state, payload) {
      state.cartItem.push(payload)
    },
    SET_SELECT_CART(state, payload) {
      state.selectCartId = payload
    },
    SET_INVENTORY_STORE(state, payload) {
      state.inventoryStore = payload
    },
    RESET_CART_DATA(state) {
      state.cartItem = []
      state.customer = null
      state.selectCartId = null
    },
    RESET_CUSTOMER(state) {
      state.customer = null
    },
    SET_REGISTRY_DETAIL(state, payload) {
      state.registryDetail = payload
    },
  },
  actions: {
    ADD_CART_ITEM: ({ commit }, payload) => {
      commit('PUSH_CART_ITEM', payload)
      commit('SET_SELECT_CART', payload.inventory_id)
    },
    REMOVE_SELECT_CART: ({ commit, getters }, indexToRemove) => {
      const cartItem = [...getters.getCartItem]

      cartItem.splice(indexToRemove, 1)
      commit('SET_CART_ITEM', cartItem)
    },
    SELECT_FIRST_ITEM: ({ commit, getters }) => {
      const cartItem = getters.getCartItem
      if (cartItem.length) {
        commit('SET_SELECT_CART', cartItem[0].inventory_id)
      }
    },
  },
}
