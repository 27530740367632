export default [
  {
    path: '/pos/point-of-sale',
    name: 'point-of-sale',
    component: () => import('@/views/pos/PointOfSale.vue'),
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: '/pos/customer-account',
    name: 'customer-account-list',
    component: () => import('@/views/pos/CustomerAccount.vue'),
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: '/pos/pos-orders',
    name: 'pos-orders',
    component: () => import('@/views/pos/PreviousOrders.vue'),
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: '/pos/purchase-history',
    name: 'pos-purchase-history',
    component: () => import('@/views/pos/PosPurchaseHistory.vue'),
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: '/pos/pos-registers',
    name: 'pos-registers',
    component: () => import('@/views/pos/PreviousRegister.vue'),
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: '/pos/customer-account/:customerId',
    name: 'customer-account-details',
    component: () => import('@/views/pos/CustomerAccountDetail.vue'),
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: '/pos/customer-pay/:registerId/:customerId/',
    name: 'customer-credit-payment',
    component: () => import('@/views/pos/PosCreditPayment.vue'),
    meta: {
      layout: 'horizontal',
    },
  },
  {
    path: '/pos',
    name: 'pos',
    component: () => import('@/views/pos/PosLayout.vue'),
    meta: {
      layout: 'horizontal',
    },
    children: [
      {
        path: 'order/standard/:registerId/:orderId?',
        name: 'pos-standard-order',
        component: () => import('@/views/pos/order/StandardOrderView.vue'),
        meta: {
          layout: 'horizontal',
        },
      },
      {
        path: 'order/:registerId/:orderId?',
        name: 'pos-order',
        component: () => import('@/views/pos/order/OrderBook.vue'),
        meta: {
          layout: 'horizontal',
        },
      },
      {
        path: 'payment/:registerId/:orderId',
        name: 'pos-payment',
        component: () => import('@/views/pos/PosPayment.vue'),
        meta: {
          layout: 'horizontal',
        },
      },
      {
        path: 'return-payment/:registerId/:orderId/:returnId/:counterId',
        name: 'pos-return-payment',
        component: () => import('@/views/pos/PosReturnPayment.vue'),
        meta: {
          layout: 'horizontal',
        },
      },
      {
        path: 'payment/:registerId/:orderId/success',
        name: 'pos-payment-success',
        component: () => import('@/views/pos/PosPaymentSucess.vue'),
        meta: {
          layout: 'horizontal',
        },
      },
      {
        path: 'payment/:registerId/:orderId/success/:returnId',
        name: 'pos-return-payment-success',
        component: () => import('@/views/pos/PosPaymentReturnSuccess.vue'),
        meta: {
          layout: 'horizontal',
        },
      },
      {
        path: 'orders/:registerId/list',
        name: 'pos-order-list',
        component: () => import('@/views/pos/OrderList.vue'),
        meta: {
          layout: 'horizontal',
        },
      },
    ],
  },
]
