import api from '@/plugins/apiService'

export default {
  namespaced: true,
  state: {
    searchPages: [],
    isSearchPages: false,
    rotatePages: {},
    leftPlansTab: 1, // takeoff & plans tab index left sidebar
    planPages: [],
    intialLoading: false,
    visualDataLoading: false,
    transfer_visual_data: {},
    selectedPages: [],
    activeCaliber: {},
    bookmarks: [],
  },
  getters: {
    GET_SEARCH_PAGES: state => state.searchPages,
    GET_IS_SEARCH_PAGES: state => state.isSearchPages,
    GET_PAGE_ROTATION: state => state.rotatePages,
    GET_LEFT_PLANS_TAB: state => state.leftPlansTab,
    GET_PLAN_PAGES: state => state.planPages,
    GET_INITIAL_LOADING: state => state.intialLoading,
    GET_VISUALDATA_LOADING: state => state.visualDataLoading,
    GET_SELECTED_PAGES: state => state.selectedPages,
    GET_SELECTED_PDF_ID: state => state.selectedPdfId,
    GET_PDF_PLANS: state => {
      // get uique pdf from array
      const arrayUniqueByKey = [...new Map(state.planPages.map(item => [item.pdf_id, item])).values()]

      return arrayUniqueByKey
    },
    GET_TRANSFER_VISUAL_DATA: state => {
      return state.transfer_visual_data
    },
    GET_ACTIVE_CALIBER: state => {
      return state.activeCaliber
    },
    GET_BOOKMARK_PAGES: state => {
      return state.bookmarks
    },
    GET_IS_PROCESSING: state => {
      const pages = state.planPages.filter(opt => opt.content_type !== null)

      return pages.some(opt => opt.is_processed < 4)
    },
  },
  mutations: {
    SET_SEARCH_PAGES: (state, payload) => {
      state.searchPages = payload
    },
    SET_PLAN_PAGES: (state, payload) => {
      state.planPages = payload
    },
    SET_IS_SEARCH_PAGES: (state, payload) => {
      state.isSearchPages = payload
    },
    SET_LEFT_PLANS_TAB: (state, payload) => {
      state.leftPlansTab = payload
    },
    SET_INITIAL_LOADING: (state, payload) => {
      state.intialLoading = payload
    },
    SET_VISUALDATA_LOADING: (state, payload) => {
      state.visualDataLoading = payload
    },
    SET_PAGE_SIGNED_URL: (state, payload) => {
      const planPages = state.planPages
      const index = planPages.findIndex(opt => {
        return opt.page_id === payload.page_id
      })
      if (index !== -1) {
        const page = state.planPages[index]
        page.signedURL = payload.signedURL
        state.planPages = [...state.planPages, page]
      }
    },
    SET_PAGE_ROTATION: (state, payload) => {
      const uuid = payload.uuid
      if (state.rotatePages[payload.uuid]) {
        let rotation = state.rotatePages[payload.uuid] + 90
        if (rotation === 360) {
          rotation = 0
        }
        state.rotatePages = {
          ...state.rotatePages,
          [uuid]: rotation,
        }
      } else {
        state.rotatePages = {
          ...state.rotatePages,
          [uuid]: 90,
        }
      }
    },
    SET_TRANSFER_VISUAL_DATA: (state, payload) => {
      state.transfer_visual_data = payload
    },
    SET_ACTIVE_CALIBER: (state, payload) => {
      const uuid = payload.uuid
      state.activeCaliber = {
        ...state.activeCaliber,
        [uuid]: payload.name,
      }
    },
    RESET_TRANSFER_VISUAL_DATA: state => {
      state.transfer_visual_data = {}
    },
    SET_PLAN_PAGES_AFTER_DELETE: (state, payload) => {
      const currentPages = [...state.planPages]
      const removeDeletePages = currentPages.filter(opt => {
        return !payload.includes(opt.page_id)
      })

      state.planPages = removeDeletePages
    },
    SET_SELECTED_PAGES: (state, payload) => {
      state.selectedPages = payload
    },
    SET_PDF_ID: (state, payload) => {
      state.selectedPdfId = payload
    },
    SET_INTERCHANGE_PAGES: (state, payload) => {
      const planPages = structuredClone(state.planPages)
      const index = planPages.findIndex(opt => {
        return opt.page_id === payload.page_id
      })

      if (index !== -1) {
        planPages[index] = payload
        state.planPages = [...planPages]
      }
    },
    SET_BOOKMARK: (state, payload) => {
      state.bookmarks = payload
    },
    RESET_BOOKMARK: state => {
      state.bookmarks = []
    },
  },
  actions: {
    PAGE_DELETE: ({ commit, getters }, payload) => {
      const pages = getters.GET_SELECTED_PAGES

      const object = {
        page_id: pages,
      }
      api
        .delete(`estimations/${payload.estimationId}/page/delete`, { data: object })
        .then(({ data }) => {
          if (data.success) {
            commit('SET_PLAN_PAGES_AFTER_DELETE', pages)
            commit('SET_SELECTED_PAGES', [])
          }
        })
    },
    COPY_PAGES: ({ getters, dispatch }, payload) => {
      const pages = getters.GET_SELECTED_PAGES

      const object = {
        page_id: pages,
        prop_id: payload.estimationId,
        prop_type: 'estimations',
      }

      api.save(`workdrive/pages/${payload.estimationId}/copy`, object).then(({ data }) => {
        if (data.success) {
          // fetch
          dispatch('FETCH_ALL_PDF_PAGES', {
            estimationId: payload.estimationId,
            intialLoading: false,
          })
        }
      })
    },
    DOWNLOAD_PAGES: async ({ commit, getters }) => {
      const pages = getters.GET_SELECTED_PAGES
      const pdf_id = getters.GET_SELECTED_PDF_ID
    
      if (!pages.length || !pdf_id) return
    
      const object = { pages }
      try {
        const { data } = await api.save(`workdrive/estimation/downloadPdf/${pdf_id}`, object)
    
        if (data?.success) {
          commit('SET_SELECTED_PAGES', [])
          commit('SET_PDF_ID', '')
        }
      } catch (error) {
        console.error('Error during page download:', error)
      }
    },
    FETCH_ALL_PDF_PAGES: async ({ commit }, payload) => {
      const config = {
        prop_type: 'estimations',
        prop_id: payload.estimationId,
      }
      commit('SET_INITIAL_LOADING', payload.intialLoading)
      api.findByParams('workdrive/plans/pages', { params: config }).then(({ data }) => {
        if (data.data?.length) {
          const modifiedData = data.data.map(opt => {
            const obj = { ...opt }
            delete obj.signedURL

            return {
              ...obj,
            }
          })
          commit('SET_PLAN_PAGES', modifiedData)
        }
      }).finally(() => {
        commit('SET_INITIAL_LOADING', false)
        commit('SET_SELECTED_PAGES', [])
      })
    },
    FETCH_BOOKMARKS: ({ commit }, payload) => {
      api
        .findAll(`${payload.component}`)
        .then(({ data }) => {
          if (data.success && data.data) {
            const response = data.data
            commit('SET_BOOKMARK', response)
          } else {
            commit('RESET_BOOKMARK')
          }
        })
        .catch(() => {
          commit(RESET_TAKE_OFF)
        })
    },
  },
}
