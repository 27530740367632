import * as XLSX from 'xlsx'
import { DateFormatUS } from '@core/utils/filter'

function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj))
}

function flattenObject(obj, parentKey = '') {
  return Object.keys(obj).reduce((acc, key) => {
    const newKey = parentKey ? `${parentKey}_${key}` : key
    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
      Object.assign(acc, flattenObject(obj[key], newKey))
    } else {
      acc[newKey] = obj[key]
    }

    return acc
  }, {})
}

const formatData = task => {
  const clonedValue = deepClone(task)

  // Flatten the object
  const flattenedValue = flattenObject(clonedValue)

  // List of keys to be deleted if they exist you can add more in array
  const idKeys = ['uuid', 'gl_id', 'cost_code_id', 'category_id', 'material_id', 'item_uuid', 'location_id', 'primary_location', 'warehouse_id', 'parent_location_uuid', 'tempId', 'sub_labor', 'parent_labor', 'vendor_id', 'cost_id', 'from_location_id', 'project_id']

  // Delete the specified keys if they exist
  idKeys.forEach(e => {
    if (Object.prototype.hasOwnProperty.call(flattenedValue, e)) {
      delete flattenedValue[e]
    }
  })

  const dateKeys = ['updatedAt', 'createdAt', 'from_date', 'to_date', 'due_date', 'closedAt']
  dateKeys.forEach(e => {
    if (Object.prototype.hasOwnProperty.call(flattenedValue, e)) {
      // Convert the date to a Date object
      const fromDateObj = new Date(flattenedValue[e])
      // Format the date to "MM/DD/YYYY" format
      flattenedValue[e] = DateFormatUS(fromDateObj)
    }
  })

  return flattenedValue
}

export const exportEstimationCalculatorData = (result, filename) => {
  const workbook = XLSX.utils.book_new()
  const worksheet = XLSX.utils.json_to_sheet(result)
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  XLSX.writeFile(workbook, filename)
}

export const exportDataToExcel = (data, filename) => {
  const result = data.map(formatData)
  const workbook = XLSX.utils.book_new()
  const worksheet = XLSX.utils.json_to_sheet(result)
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  XLSX.writeFile(workbook, filename)
}

export const readExcelData = data => {
  const readOpts = { // <--- need these settings in readFile options
    cellText: false,
    cellDates: true,
  }
  const workbook = XLSX.readFile(data, readOpts)
  const sheetName = workbook.SheetNames[0]
  const sheet = workbook.Sheets[sheetName]
  const options = {
    raw: false,
    dateNF: 'YYYY"-"MM"-"DD',
  }
  const jsonData = XLSX.utils.sheet_to_json(sheet, options)

  return jsonData
}

export const exportTemplateToExcel = (headers, data, filename) => {
  const workbook = XLSX.utils.book_new()
  const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data])
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  XLSX.writeFile(workbook, filename)
}

export const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
      return false;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  keys1.sort();
  keys2.sort();

  for (let i = 0; i < keys1.length; i++) {
      if (keys1[i] !== keys2[i]) return false;
      if (!deepEqual(obj1[keys1[i]], obj2[keys2[i]])) return false;
  }
  console.log('jdgfjhds')
  return true;
}

// export const exportDataToCsv = (header, data, filename) => {
//   const content = objectToCsv(header, data)
//   // Create a blob
//   const blob = new Blob([content], { type: 'text/xls;charset=utf-8;' })
//   const url = URL.createObjectURL(blob)

//   // Create a link to download it
//   const pom = document.createElement('a')
//   pom.href = url
//   pom.setAttribute('download', filename)
//   pom.click()
//   URL.revokeObjectURL(url)
// }

// const objectToCsv = function (header, data) {
//   const csvRows = []

//   /* Get headers as every csv data format
//   has header (head means column name)
//   so objects key is nothing but column name
//   for csv data using Object.key() function.
//   We fetch key of object as column name for
//   csv */

//   const headers = header

//   /* Dynamic way to create header with data */
//   // const headers = [...new Set(data.flatMap(obj => Object.keys(obj)))];

//   /* Using push() method we push fetched
//      data into csvRows[] array */
//   csvRows.push(headers.join(','))

//   // Loop to get value of each objects key
//   for (const row of data) {
//     const values = headers.map(header => {
//       const val = row[header]

//       return val
//     })

//     // To add, separator between each value
//     csvRows.push(values.join(','))
//   }

//   /* To add new line for each objects values
//      and this return statement array csvRows
//      to this function. */
//   return csvRows.join('\n')
// }

export const downloadTemplateCSV = (data, filename) => {
  const blob = new Blob([data], { type: 'text/xls;charset=utf-8;' })
  const url = URL.createObjectURL(blob)

  // Create a link to download it
  const pom = document.createElement('a')
  pom.href = url
  pom.setAttribute('download', filename)
  pom.click()
}
