<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on: tooltip, attrs }">
        <v-icon
          v-bind="attrs"
          data-cy="launcher-btn"
          style="cursor: pointer"
          :color="light ? 'secondary' : 'white'"
          v-on="{ ...tooltip }"
          @click="$emit('openLauncher')"
        >
          {{ icons.mdiDotsGrid }}
        </v-icon>
      </template>
      <span class="pa-2">App Launcher</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mdiDotsGrid } from '@mdi/js'

import { ref } from '@vue/composition-api'
import store from '@/store'
import { storeActions } from '@/store/storeConstants'

export default {
  props: {
    light: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { ESTIMATE_TAB_REQUEST, SETUP_TAB_REQUEST, REPORT_TAB_REQUEST, DASHBOARD_TAB_REQUEST } =
      storeActions
    const openLauncherModal = ref(false)

    const nativeClick = item => {
      console.log('[41] =>', item, 'sss')
      if (item.title === 'CRM') {
        store.dispatch('tabPersist/SET_TAB_STATE', { attribute: 'CRM Dashboard' })
      }
      if (item.title === 'Estimation') {
        store.dispatch(`setupTab/${ESTIMATE_TAB_REQUEST}`, { attribute: 'TakeOffs' })
      }
      if (item.title === 'Setup') {
        store.dispatch(`setupTab/${SETUP_TAB_REQUEST}`, { attribute: 'CRM' })
      }
      if (item.title === 'Reports') {
        store.dispatch(`setupTab/${REPORT_TAB_REQUEST}`, { attribute: 'Reports' })
      }
      if (item.title === 'Dashboards') {
        store.dispatch(`setupTab/${DASHBOARD_TAB_REQUEST}`, { attribute: 'Dashboards' })
      }
    }

    return {
      openLauncherModal,
      nativeClick,
      icons: {
        mdiDotsGrid,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-list.app-launcher-list.v-sheet.theme--light {
  border: 0.9px solid #cecccc;
  border-radius: 6px;
}
.v-list.app-launcher-list .btn-list-item.theme--light:hover {
  background: #f3f3f3;
  border-radius: 10px;
}
.v-list.app-launcher-list .btn-list-item.theme--light .v-list-item__title {
  font-size: 14.5px;
  color: #2e2d2d;
}
</style>
