import {
  mdiWarehouse,
  mdiSilo,
  mdiListStatus,
  mdiReceipt,
  mdiTruckDelivery,
  mdiNintendoSwitch,
  mdiVectorLink,
  mdiLogout,
  mdiLogin,
  mdiAdjust,
  mdiHomeLock,
  mdiHomeLockOpen,
  mdiNotebookRemove,
  mdiStore,
  mdiNumeric,
  mdiTimelineCheck,
  mdiClipboardClockOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'Retail Inventory Management',
  },
  {
    title: 'Warehouse',
    icon: mdiWarehouse,
    to: 'retail-warehouse',
    module: 'warehouse',
  },
  {
    title: 'Inventory',
    icon: mdiSilo,
    to: 'retail-inventory-list',
    module: 'inventory',
  },
  {
    title: 'Purchase Order',
    icon: mdiListStatus,
    to: 'retail-purchase-order',
    module: 'purchaseOrder',
  },
  {
    title: 'Change Order',
    icon: mdiListStatus,
    to: 'retail-inv-change-order',
    module: 'changeOrder',
  },
  {
    title: 'Receipt',
    icon: mdiReceipt,
    to: 'retail-receipts-list',
    module: 'receipt',
  },
  {
    title: 'Internal Transfer',
    icon: mdiVectorLink,
    to: 'retail-internal-transfer',
    module: 'internalTransfer',
  },
  {
    title: 'Inventory Adjustment',
    icon: mdiAdjust,
    to: 'retail-inventory-adjustment-list',
    module: 'inventoryAdjustment',
  },
  {
    title: 'Physical Count',
    icon: mdiNumeric,
    to: 'retail-physical-count-list',
    module: 'physicalCount',
  },
  {
    title: 'Approve Physical Count',
    icon: mdiTimelineCheck,
    to: 'retail-approve-physical-count-list',
    module: 'physicalCount',
  },
  {
    title: 'Inventory Movement',
    icon: mdiClipboardClockOutline,
    to: 'retail-inventory-movement',
    module: 'inventoryMovement',
  },
  {
    title: 'Void',
    icon: mdiNotebookRemove,
    to: 'retail-inventory-void',
    module: 'inventoryMovement',
  },
]
