const support = [
    {
        path: '/support',
        name: 'support',
        component: () => import('@/views/setup/support/support.vue'),
        meta: {
          layout: 'horizontal',
        },
      },
      {
        path: '/support/create-support-ticket',
        name: 'support-ticket',
        component: () => import('@/views/setup/support/SupportTicket.vue'),
        meta: {
          layout: 'horizontal',
        },
      },
      {
        path: '/support/create-support/:uuid/update',
        name: 'update-support-ticket',
        component: () => import('@/views/setup/support/SupportTicket.vue'),
        meta: {
          layout: 'horizontal',
        },
      },
      {
        path: '/support/support-detail/:support_ticket_id/:uuid',
        name: 'support-detail',
        component: () => import('@/views/setup/support/SupportDetail.vue'),
        meta: {
            layout: 'horizontal',
        },
      },
]
export default support