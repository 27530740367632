import Toaster from '@/@core/utils/sweetToast'
import router from '@/router'
import store from '@/store'
import { storeGetters, storeActions } from '@/store/storeConstants'
import { reactive, toRefs } from '@vue/composition-api'
import { exportTemplateToExcel } from '@core/utils/csvService'

export default function useProject(item) {
  const { GET_PROJECT_ID } = storeGetters
  const { GET_ALL_RECORDS, GET_BY_ID, SET_PROJECT, SET_PROJECT_DETAIL_STATE, CREATE, CUSTOM_REQUEST } = storeActions

  const state = reactive({
    loading: false,
    downloadProcess: false,
    statusOption: [],
  })
  const fetchProjects = async value => {
    const payLoad = {
      component: 'project-management/projects',
      filter: {
        search: value,
        // limit: -1
      },
    }
    try {
      const { data } = await store.dispatch(`common/${GET_ALL_RECORDS}`, payLoad)
      if (data.success && data.data?.length) {
        return data.data
      }

      return []
    } catch (error) {
      return []
    }
  }

  const getProjectId = () => {
    const stateProjectId = store.getters[`projectManagement/${GET_PROJECT_ID}`]
    if (stateProjectId !== null) {
      return stateProjectId
    }
    const routerProjectId = router.currentRoute.params.projectId
    if (routerProjectId !== undefined) {
      const payload = {
        projectId: routerProjectId,
      }
      store.dispatch(`projectManagement/${SET_PROJECT}`, payload)

      return routerProjectId
    }

    return undefined
  }

  const getProjectDetails = () => {
    const config = {
      component: 'project-management/projects',
      objectId: getProjectId(),
    }
    store
      .dispatch(`common/${GET_BY_ID}`, config)
      .then(({ data }) => {
        if (data.success) {
          store.dispatch(`projectManagement/${SET_PROJECT_DETAIL_STATE}`, data.data)
        } else {
          Toaster.error(data.message, 'warning')
        }
      })
      .catch(() => {
        Toaster.error('An error occur, please try again later', 'warning')
      })
  }

  const fetchProjectslist = async value => {
    const payLoad = {
      component: 'project-management/projects',
      filter: {
        search: value,
        limit: -1
      },
    }
    try {
      state.loading = true
      const { data } = await store.dispatch(`common/${GET_ALL_RECORDS}`, payLoad)
      state.loading = false
      if (data.success && data.data?.length) {
        return data.data
      }

      return []
    } catch (error) {
      state.loading = true
      return []
    }
  }

  const goToComponent = () => {
    let projectId = getProjectId()
    if (projectId === undefined) {
      Toaster.error('Please select a project to view this page', 'error')

      return
    }
    if (item.to === 'project-management') {
      projectId = undefined
      const payload = {
        projectId,
      }
      store.dispatch(`projectManagement/${SET_PROJECT}`, payload)
    }
    router.push({
      name: item.to,
      params: {
        projectId,
      },
    })
  }

  const downloadPdf = (fileName, fileId, intervalCount = 0) => {
    const payload = {
      component: `reportservice/download/pdf/${fileId}`,
      config: { responseType: 'blob' },
    }
    state.downloadProcess = true
    store.dispatch(`common/${CUSTOM_REQUEST}`, payload).then(resp => {
      state.downloadProcess = false
      try {
        const url = window.URL.createObjectURL(new Blob([resp.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.pdf`)
        link.click()
        link.remove()
      } catch (err) {
        console.log(err)
      }
    }).catch(() => {
      state.downloadProcess = false
      if (intervalCount < 5) {
        // eslint-disable-next-line no-plusplus, no-param-reassign
        intervalCount++
        state.downloadProcess = true
        setTimeout(() => {
          downloadPdf(fileName, fileId, intervalCount)
        }, 2000)
      }
    })
  }

  const printInvoice = (module, itemId) => {
    const payload = {
      component: 'reportservice',
      object: {
        type: module,
        prop_id: itemId,
      },
    }
    state.downloadProcess = true
    store.dispatch(`common/${CREATE}`, payload).then(({ data }) => {
      state.downloadProcess = false
      if (data.success && data.data) {
        downloadPdf(module, data.data.uuid)
      }
    }).catch(error => {
      console.log(error)
      state.downloadProcess = false
    })
  }

  const projectPrintInvoice = (module, itemId, projectId) => {
    const payload = {
      component: 'reportservice',
      object: {
        type: module,
        prop_id: itemId,
        project_id: projectId,
      },
    }
    state.downloadProcess = true
    store.dispatch(`common/${CREATE}`, payload).then(({ data }) => {
      state.downloadProcess = false
      if (data.success && data.data) {
        downloadPdf(module, data.data.uuid)
      }
    }).catch(error => {
      console.log(error)
      state.downloadProcess = false
    })
  }
  const idFieldSorting = items => {
    const clonedValue = items
    const idKeys = ['uuid', 'cost_code_id', 'category_id']
    idKeys.forEach(e => {
      if (Object.prototype.hasOwnProperty.call(clonedValue, e)) {
        delete clonedValue[e]
      }
    })

    return clonedValue
  }

  const exportTemplate = async (moduleSchema, templateName) => {
    try {
      const header = moduleSchema.headers.flatMap(obj => obj.value || obj.fieldName)

      exportTemplateToExcel(header, [], `${templateName}_template.xlsx`)
      Toaster.success('Request approved for performing operation', 'success')
    } catch (error) {
      Toaster.error('An eror occur, please try again later', 'warning')
    }
  }

  const loadDynamicStatus = (subModule, type) => {
    const payload = {
      component: `setup/statuses/${subModule}`,
      filter: {
        field_name: type || 'status',
      },
    }
    state.loading = true
    store.dispatch(`common/${GET_ALL_RECORDS}`, payload).then(({ data }) => {
      state.loading = false
      if (data.success && data.data) {
        state.statusOption = data.data.sort((a, b) => {
          return a?.sequence - b?.sequence
        })
      }
    }).catch(error => { 
      console.log(error)
      state.loading = false
    })
  }

  return {
    exportTemplate,
    idFieldSorting,
    fetchProjects,
    fetchProjectslist,
    goToComponent,
    getProjectId,
    getProjectDetails,
    printInvoice,
    projectPrintInvoice,
    loadDynamicStatus,
    ...toRefs(state),
  }
}
