const inventory = [
  {
    path: '/inventory-management',
    name: 'inventory-management',
    component: () => import('@/components/inventory-management/common/InventoryLayout.vue'),
    children: [
      {
        path: 'warehouse',
        name: 'Warehouse',
        component: () => import('@/views/inventory-management/warehouse/WarehouseList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'warehouse/:uuid/:code/view',
        name: 'warehouse-details',
        component: () => import('@/views/inventory-management/warehouse/WarehouseView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'inventory',
        name: 'inventory-list',
        component: () => import('@/views/inventory-management/inventory/InventoryList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'inventory/create',
        name: 'create-inventory',
        component: () => import('@/views/inventory-management/inventory/InventoryForm.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'inventory/:code/edit',
        name: 'update-inventory',
        component: () => import('@/views/inventory-management/inventory/InventoryForm.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'inventory/:code/view/:material_id',
        name: 'view-inventory',
        component: () => import('@/views/inventory-management/inventory/InventoryView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'purchase-order',
        name: 'purchase-order',
        component: () => import('@/views/inventory-management/purchase-order/POList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'po/create',
        name: 'create-purchase-order',
        component: () => import('@/views/inventory-management/purchase-order/CreatePO.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'po/:uuid/view',
        name: 'view-po',
        component: () => import('@/views/inventory-management/purchase-order/POView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'change-order',
        name: 'inv-change-order',
        component: () => import('@/views/inventory-management/change-order/COList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'co/create/:po_id?',
        name: 'inv-create-change-order',
        component: () => import('@/views/inventory-management/change-order/CreateCO.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'co/:uuid/view',
        name: 'inv-view-co',
        component: () => import('@/views/inventory-management/change-order/COView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'receipts',
        name: 'receipts-list',
        component: () => import('@/views/inventory-management/receipts/ReceiptList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'receipt/create/:po_id?',
        name: 'create-receipt',
        component: () => import('@/views/inventory-management/receipts/CreateReceipt.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'receipt/:uuid/view',
        name: 'view-receipt',
        component: () => import('@/views/inventory-management/receipts/ReceiptView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'delivery',
        name: 'delivery-list',
        component: () => import('@/views/inventory-management/delivery/DeliveryList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'delivery/create',
        name: 'create-delivery',
        component: () => import('@/views/inventory-management/delivery/CreateDelivery.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'delivery/:uuid/view',
        name: 'view-delivery',
        component: () => import('@/views/inventory-management/delivery/DeliveryView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'shop-pull',
        name: 'shop-pull-list',
        component: () => import('@/views/inventory-management/shop-pull/ShopPullList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'shop-pull/create',
        name: 'create-shop-pull',
        component: () => import('@/views/inventory-management/shop-pull/CreateShopPull.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'shop-pull/:uuid/view',
        name: 'view-shop-pull',
        component: () => import('@/views/inventory-management/shop-pull/ShopPullView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'shop-requisition',
        name: 'shop-requition-list',
        component: () => import('@/views/inventory-management/shop-requisition/RequisitionList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'shop-requisition/create',
        name: 'create-shop-requisition',
        component: () => import('@/views/inventory-management/shop-requisition/CreateRequisition.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'shop-requisition/:uuid/view',
        name: 'view-shop-requisition',
        component: () => import('@/views/inventory-management/shop-requisition/RequisitionView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'requisition',
        name: 'requisition',
        component: () => import('@/views/inventory-management/requisition/RequisitionList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'requisition/create',
        name: 'create-requisition',
        component: () => import('@/views/inventory-management/requisition/CreateRequisition.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'requisition/:uuid/view',
        name: 'view-requisition',
        component: () => import('@/views/inventory-management/requisition/RequisitionView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'without-requisition',
        name: 'without-requisition',
        component: () => import('@/views/inventory-management/without-requisition/RequisitionList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'without-requisition/create',
        name: 'create-without-requisition',
        component: () => import('@/views/inventory-management/without-requisition/CreateRequisition.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'without-requisition/:uuid/view',
        name: 'view-without-requisition',
        component: () => import('@/views/inventory-management/without-requisition/RequisitionView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'issue-against-requisition',
        name: 'issue-against-requisition',
        component: () => import('@/views/inventory-management/issue-against-requisition/IssueAgainstRequisition.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'issue-against-requisition/create/:requisition_id?',
        name: 'issue-against-requisition-form',
        component: () => import('@/views/inventory-management/issue-against-requisition/IssueAgainstRequisitionForm.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'issue-against-requisition/:uuid/view',
        name: 'view-requisition-issue',
        component: () => import('@/views/inventory-management/issue-against-requisition/IssueRequisitionView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'internal-transfer',
        name: 'internal-transfer',
        component: () => import('@/views/inventory-management/internal-transfer/InternalTransferList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'internal-transfer/create',
        name: 'create-internal-transfer',
        component: () => import('@/views/inventory-management/internal-transfer/CreateInternalTransfer.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'internal-transfer/:uuid/view',
        name: 'view-internal-transfer',
        component: () => import('@/views/inventory-management/internal-transfer/InternalTransferView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'sign-out',
        name: 'inventory-signout',
        component: () => import('@/views/inventory-management/sign-out/SignOutList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'signout/create',
        name: 'create-signout',
        component: () => import('@/views/inventory-management/sign-out/CreateSignOut.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'signout/:uuid/view',
        name: 'view-signout',
        component: () => import('@/views/inventory-management/sign-out/SignOutView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'bring-back',
        name: 'bring-back',
        component: () => import('@/views/inventory-management/bring-back/BringBackList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'bring-back/create',
        name: 'create-bring-back',
        component: () => import('@/views/inventory-management/bring-back/CreateBringBack.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'bring-back/:uuid/view',
        name: 'view-bring-back',
        component: () => import('@/views/inventory-management/bring-back/BringBackView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'inventory-adjustments',
        name: 'inventory-adjustment-list',
        component: () => import('@/views/inventory-management/inventory-adjustment/InventoryAdjustmentList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'inventory-adjustment/create',
        name: 'create-inventory-adjustment',
        component: () => import('@/views/inventory-management/inventory-adjustment/CreateInventoryAdjustment.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'inventory-adjustment/:uuid/view',
        name: 'view-inventory-adjustment',
        component: () => import('@/views/inventory-management/inventory-adjustment/InventoryAdjustmentView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'physical-count',
        name: 'physical-count-list',
        component: () => import('@/views/inventory-management/physical-count/PhysicalCountList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'physical-count/create',
        name: 'create-physical-count',
        component: () => import('@/views/inventory-management/physical-count/CreatePhysicalCount.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'physical-count/:uuid/view',
        name: 'view-physical-count',
        component: () => import('@/views/inventory-management/physical-count/PhysicalCountView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'approve-physical-count',
        name: 'approve-physical-count-list',
        component: () => import('@/views/inventory-management/approve-physical-count/PhysicalCountList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'approve-physical-count/:uuid/view',
        name: 'view-approve-physical-count',
        component: () => import('@/views/inventory-management/approve-physical-count/PhysicalCountView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'void',
        name: 'inventory-void',
        component: () => import('@/views/inventory-management/void/InventoryVoidList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'inventory-movement',
        name: 'inventory-movement',
        component: () => import('@/views/inventory-management/inventory-movement/InventoryMovementView.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: ':prop_type/import',
        name: 'import-inventory',
        component: () => import('@/components/inventory-management/common/InvImportExcelData.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'warehouse/:uuid/:code/:prop_type/import',
        name: 'import-warehouse-location',
        component: () => import('@/components/inventory-management/common/InvImportExcelData.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
      {
        path: 'stock-report',
        name: 'inventory-stock-report',
        component: () => import('@/views/inventory-management/stock-report/StockReportList.vue'),
        meta: {
          layout: 'content',
          app: 'inventoryManagement',
          appId: 11,
        },
      },
    ],
  },
]

export default inventory
