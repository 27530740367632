import { storeActions, storeGetters, storeMutations } from '@/store/storeConstants'
import api from '@/plugins/apiService'

const { LOGIN, LOGOUT, REGISTER, USER_PROFILE } = storeActions
const {
  GET_ALL_PERMISSIONS,
  GET_USER_INFO,
  DECODE_TOKEN_INFO,
  GET_AUTH_TOKEN,
} = storeGetters
const {
  AUTH_USER,
  SET_PERMISSION,
  AUTH_LOGOUT,
  AUTH_SET_TOKEN,
} = storeMutations

const getUser = () => {
  const consoleState = JSON.parse(localStorage.getItem('user'))
  if (consoleState) {
    return consoleState
  }

  return {}
}

const getToken = () => {
  const consoleState = localStorage.getItem('token')
  if (consoleState) {
    return consoleState
  }

  return null
}
export default {
  namespaced: true,
  state: {
    permissions: null,
    user: getUser(),
    token: getToken(),
  },
  getters: {
    [GET_ALL_PERMISSIONS]: state => {
      return state.permissions
    },
    [GET_USER_INFO]: state => {
      return state.user
    },
    [GET_AUTH_TOKEN]: state => {
      return state.token
    },
    [DECODE_TOKEN_INFO]: state => {
      if (state.token) {
        const { token } = state
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map(c => {
              return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
            })
            .join(''),
        )
        const jsonObject = JSON.parse(jsonPayload)

        return jsonObject
      }

      return {}
    },
  },
  mutations: {
    [SET_PERMISSION]: (state, payload) => {
      state.permissions = payload
    },
    [AUTH_USER]: (state, res) => {
      state.user = res
    },
    [AUTH_SET_TOKEN]: (state, res) => {
      state.token = res
    },
    [AUTH_LOGOUT]: state => {
      window.localStorage.clear()
      state.permissions = {}
      state.user = {}
      state.token = null
      window.location.reload()
    },
  },
  actions: {
    [LOGIN]: ({ commit }, payload) => {
      return api.save('/ac-api/partner/login', payload).then(({ data }) => {
        if (data.data && data.data.resetLink) {
          return data
        }
        if (data.success && data.data) {
          try {
            commit(AUTH_SET_TOKEN, data.data.token)

            window.localStorage.setItem('token', data.data.token)
            window.localStorage.setItem('session', null)
          } catch (error) {
            return {
              success: false,
              message: 'Bad Authentication data. Malformed data',
            }
          }
        }

        return data
      })
    },
    [USER_PROFILE]: ({ commit }, payload) => {
      return api.save('ac-api/partner/profile', payload).then(({ data }) => {
        const userData = data.data
        userData.profile = userData.profile || {}
        window.localStorage.setItem('user', JSON.stringify(userData))
        commit(AUTH_USER, userData)

        return data
      })
    },
    [REGISTER]: (context, payload) => {
      return api.save('ac-api/partner/request/', payload).then(res => res)
    },
    [LOGOUT]: ({ commit }) => {
      commit(AUTH_LOGOUT)
    },
  },
}
