const retailInventory = [
  {
    path: '/retail-inventory',
    name: 'inventory-management',
    component: () => import('@/components/inventory-management/common/InventoryLayout.vue'),
    children: [
      {
        path: 'warehouse',
        name: 'retail-warehouse',
        component: () => import('@/views/retail/inventory/warehouse/WarehouseList.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'warehouse/:uuid/:code/view',
        name: 'retail-warehouse-details',
        component: () => import('@/views/retail/inventory/warehouse/WarehouseView.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'inventory',
        name: 'retail-inventory-list',
        component: () => import('@/views/retail/inventory/inventory/InventoryList.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'inventory/create',
        name: 'retail-create-inventory',
        component: () => import('@/views/retail/inventory/inventory/InventoryForm.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'inventory/:code/edit',
        name: 'retail-update-inventory',
        component: () => import('@/views/retail/inventory/inventory/InventoryForm.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'inventory/:code/view/:material_id',
        name: 'retail-view-inventory',
        component: () => import('@/views/retail/inventory/inventory/InventoryView.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'purchase-order',
        name: 'retail-purchase-order',
        component: () => import('@/views/retail/inventory/purchase-order/POList.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'po/create',
        name: 'retail-create-purchase-order',
        component: () => import('@/views/retail/inventory/purchase-order/CreatePO.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'po/:uuid/view',
        name: 'retail-view-po',
        component: () => import('@/views/retail/inventory/purchase-order/POView.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'change-order',
        name: 'retail-inv-change-order',
        component: () => import('@/views/retail/inventory/change-order/COList.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'co/create/:po_id?',
        name: 'retail-inv-create-change-order',
        component: () => import('@/views/retail/inventory/change-order/CreateCO.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'co/:uuid/view',
        name: 'retail-inv-view-co',
        component: () => import('@/views/retail/inventory/change-order/COView.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'receipts',
        name: 'retail-receipts-list',
        component: () => import('@/views/retail/inventory/receipts/ReceiptList.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'receipt/create/:po_id?',
        name: 'retail-create-receipt',
        component: () => import('@/views/retail/inventory/receipts/CreateReceipt.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'receipt/:uuid/view',
        name: 'retail-view-receipt',
        component: () => import('@/views/retail/inventory/receipts/ReceiptView.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'internal-transfer',
        name: 'retail-internal-transfer',
        component: () => import('@/views/retail/inventory/internal-transfer/InternalTransferList.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'internal-transfer/create',
        name: 'retail-create-internal-transfer',
        component: () => import('@/views/retail/inventory/internal-transfer/CreateInternalTransfer.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'internal-transfer/:uuid/view',
        name: 'retail-view-internal-transfer',
        component: () => import('@/views/retail/inventory/internal-transfer/InternalTransferView.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'inventory-adjustments',
        name: 'retail-inventory-adjustment-list',
        component: () => import('@/views/retail/inventory/inventory-adjustment/InventoryAdjustmentList.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'inventory-adjustment/create',
        name: 'retail-create-inventory-adjustment',
        component: () => import('@/views/retail/inventory/inventory-adjustment/CreateInventoryAdjustment.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'inventory-adjustment/:uuid/view',
        name: 'retail-view-inventory-adjustment',
        component: () => import('@/views/retail/inventory/inventory-adjustment/InventoryAdjustmentView.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'physical-count',
        name: 'retail-physical-count-list',
        component: () => import('@/views/retail/inventory/physical-count/PhysicalCountList.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'physical-count/create',
        name: 'retail-create-physical-count',
        component: () => import('@/views/retail/inventory/physical-count/CreatePhysicalCount.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'physical-count/:uuid/view',
        name: 'retail-view-physical-count',
        component: () => import('@/views/retail/inventory/physical-count/PhysicalCountView.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'approve-physical-count',
        name: 'retail-approve-physical-count-list',
        component: () => import('@/views/retail/inventory/approve-physical-count/PhysicalCountList.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'approve-physical-count/:uuid/view',
        name: 'retail-view-approve-physical-count',
        component: () => import('@/views/retail/inventory/approve-physical-count/PhysicalCountView.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'void',
        name: 'retail-inventory-void',
        component: () => import('@/views/retail/inventory/void/InventoryVoidList.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'inventory-movement',
        name: 'retail-inventory-movement',
        component: () => import('@/views/retail/inventory/inventory-movement/InventoryMovementView.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: ':prop_type/import',
        name: 'retail-import-inventory',
        component: () => import('@/components/inventory-management/common/InvImportExcelData.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
      {
        path: 'warehouse/:uuid/:code/:prop_type/import',
        name: 'retail-import-warehouse-location',
        component: () => import('@/components/inventory-management/common/InvImportExcelData.vue'),
        meta: {
          layout: 'content',
          app: 'retailInventory',
          appId: 11,
        },
      },
    ],
  },
]

export default retailInventory
