export default {
  namespaced: true,
  state: {
    isStartDrawing: false,
    toolType: 0,
    annotationType: 0,
    commentObjId: null,
    showComments: false,
    isStartTakeOffMeasure: false,
    overlayLayers: [],
    showColorBox: false,
    showEditTakeOff: false,
    selectedTakeOff: {},
    selectedObjectColor: null,
    selectObjectName: null,
    calibrationList: [],
    activeCalibration: {
      uuid: 1,
      name: '1',
      type: 'standard',
      value: 1,
    },
    activeTakeOff: {
      uuid: null,
      name: null,
      label: null,
      location: null,
      path_style: {
        strokeColor: 'red',
      },
      assembly: null,
      materials: [],
    },
  },
  getters: {
    getIsStartDrawing: state => state.isStartDrawing,
    getToolType: state => state.toolType,
    getAnnotationType: state => state.annotationType,
    getCommentObjId: state => state.commentObjId,
    getActiveTakeOff: state => state.activeTakeOff,
    getIsTakeOffMeasureStarted: state => state.isStartTakeOffMeasure,
    getOverlayLayers: state => state.overlayLayers,
    getActiveCalibrationName: state => state.activeCalibration.name,
    getActiveCalibration: state => state.activeCalibration,
    getCalibrationList: state => state.calibrationList,
    showComments: state => state.showComments,
    getShowColorBox: state => state.showColorBox,
    getSelectedObjectName: state => state.selectObjectName,
    getShowEditTakeOff: state => state.showEditTakeOff,
    getSelectedTakeOff: state => state.selectedTakeOff
  },
  mutations: {
    SET_TOOL_TYPE(state, payload) {
      state.toolType = payload
    },
    SET_ANNOTATION_TYPE(state, payload) {
      state.annotationType = payload
    },
    SET_IS_START_DRAWING(state, payload) {
      state.isStartDrawing = payload
    },
    SET_ANNOTATION_SELECT(state, payload) {
      state.isToolSelect = payload
    },
    SET_COMMENT_OBJ_ID(state, payload) {
      state.commentObjId = payload
    },
    SET_START_TAKE_OFF_MEASURE(state, payload) {
      state.isStartTakeOffMeasure = true
      state.activeTakeOff = payload
    },
    SET_STOP_TAKE_OFF_MEASURE(state) {
      state.isStartTakeOffMeasure = false
      state.activeTakeOff = {
        uuid: null,
        name: null,
        label: null,
        location: null,
        path_style: {
          strokeColor: 'red',
        },
        assembly: null,
        materials: [],
      }
    },
    SET_OVERLAY_LAYER(state, payload) {
      state.overlayLayers.push({ ...payload.page, ...{ overlay: true, visible: true } })
    },
    SET_ACTIVE_CALIBRATION(state, payload) {
      state.activeCalibration = payload
    },
    UPDATE_CALIBRATION_LIST(state, payload){
      if(!state.calibrationList.find(i => i.uuid === payload.uuid)){
        state.calibrationList.push(payload)
      }
    },
    SET_CALIBRATION_LIST(state, payload){
      state.calibrationList = []
      payload.forEach(c => {
        if(!state.calibrationList.find(i => i.uuid === c.uuid)){
          state.calibrationList.push(c)
        }
      })
    },
    RESET_STATE(state) {
      state.overlayLayers = []
      state.calibrationList = []
      state.activeCalibration = {
        uuid: 1,
        name: '1',
        type: 'standard',
        value: 1,
      }
    },
    SET_SHOW_COMMENT(state, payload) {
      state.showComments = payload
    },
    SHOW_EDIT_TAKE_OFF(state, payload) {
      state.showEditTakeOff = payload
    },
    UPDATE_MATERIALS_ON_TAKE_OFF(state, payload) {
      if (payload.uuid === state.activeTakeOff.uuid) {
        state.activeTakeOff.materials = payload.materials
      }
    },
    SHOW_COLOR_BOX(state, payload) {
      state.showColorBox = payload
    },
    SET_OBJECT_NAME(state, payload) {
      state.selectObjectName = payload
    },
    SET_SELECTED_TAKE_OFF(state, payload) {
      state.selectedTakeOff = payload
    },
  },
}
