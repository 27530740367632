import { nanoid } from 'nanoid'
import {
  mdiChartTree,
  mdiViewDashboard,
  mdiCalculatorVariantOutline,
  mdiOfficeBuildingOutline,
  mdiAccount,
} from '@mdi/js'
import { storeActions } from '@/store/storeConstants'
import store from '@/store'

const { SUB_TAB_REQUEST, CRAETE_TABS } = storeActions

export default () => {
  const reportSubTab = (item, redirectUrl, icon = mdiChartTree) => {
    const tabObj = {
      title: item.name,
      icon,
      to: redirectUrl,
      key: item.uuid,
      id: nanoid(),
      paramName: item.paramName,
    }
    const config = {
      component: 'setup/user-preferences',
      key: 'report',
      prop_type: 'report',
      item: tabObj,
    }
    store.dispatch(`tabSystem/${SUB_TAB_REQUEST}`, config)
  }

  const reportTab = (item, redirectUrl) => {
    const payload = {
      title: item.name,
      icon: mdiCalculatorVariantOutline,
      to: redirectUrl,
      key: item.uuid,
      id: nanoid(),
      paramName: item.paramName,
      subTab: [],
    }

    const config = {
      component: 'setup/user-preferences',
      key: 'report',
      prop_type: 'report',
      item: payload,
    }
    store.dispatch(`tabSystem/${CRAETE_TABS}`, config)
  }

  const estimationTab = (item, redirectUrl) => {
    const payload = {
      title: item.name,
      icon: mdiCalculatorVariantOutline,
      to: redirectUrl,
      key: item.uuid,
      id: nanoid(),
      paramName: item.paramName,
      subTab: [],
    }
    const config = {
      component: 'setup/user-preferences',
      key: 'estimation',
      prop_type: 'estimate',
      item: payload,
    }
    store.dispatch(`tabSystem/${CRAETE_TABS}`, config)
  }

  // estimation tool tab
  const gotoEstimationToolTab = (item, redirectUrl) => {
    const payload = {
      title: item.name,
      icon: mdiViewDashboard,
      to: redirectUrl,
      estimateId: item.estimateId,
      pdfId: item.pdfId,
      pageId: item.pageId,
      id: nanoid(),
      subTab: [],
    }
    const config = {
      component: 'setup/user-preferences',
      key: 'estimate-tool',
      prop_type: 'estimate-tool',
      item: payload,
    }
    store.dispatch(`estimateToolTab/${CRAETE_TABS}`, config)
  }

  // default icon for crm is account
  const gotoCrmTab = (item, redirectUrl, icon = mdiOfficeBuildingOutline) => {
    const tabObj = {
      title: item.name,
      icon,
      to: redirectUrl,
      key: item.uuid,
      id: nanoid(),
      subTab: [],
      paramName: item.paramName,
    }
    const config = {
      component: 'setup/user-preferences',
      key: 'crm',
      prop_type: 'accounts',
      item: tabObj,
    }
    store.dispatch(`tabSystem/${CRAETE_TABS}`, config)
  }
  // default icon for crm is account
  const gotoCustomerTab = (item, redirectUrl, icon = mdiOfficeBuildingOutline) => {
    const tabObj = {
      title: item.name,
      icon,
      to: redirectUrl,
      key: item.uuid,
      id: nanoid(),
      subTab: [],
      paramName: item.paramName,
    }
    const config = {
      component: 'setup/user-preferences',
      key: 'retailCustomer',
      prop_type: 'retailCustomer',
      item: tabObj,
    }
    store.dispatch(`tabSystem/${CRAETE_TABS}`, config)
  }
  // crm sub tab
  const gotoSubTab = (item, redirectUrl, icon = mdiOfficeBuildingOutline) => {
    const tabObj = {
      title: item.name,
      icon,
      to: redirectUrl,
      key: item.uuid,
      id: nanoid(),
      paramName: 'uuid',
    }
    const config = {
      component: 'setup/user-preferences',
      key: 'crm',
      prop_type: 'accounts',
      item: tabObj,
    }
    store.dispatch(`tabSystem/${SUB_TAB_REQUEST}`, config)
  }

  const customerSubTab = (item, redirectUrl, icon = mdiOfficeBuildingOutline) => {
    const tabObj = {
      title: item.name,
      icon,
      to: redirectUrl,
      key: item.uuid,
      id: nanoid(),
      paramName: 'uuid',
    }
    const config = {
      component: 'setup/user-preferences',
      key: 'retailCustomer',
      prop_type: 'retailCustomer',
      item: tabObj,
    }
    store.dispatch(`tabSystem/${SUB_TAB_REQUEST}`, config)
  }

  const gotoVendorTab = (item, redirectUrl, queryParam) => {
    const tabObj = {
      title: item.name,
      icon: mdiAccount,
      to: redirectUrl,
      key: item.uuid,
      id: nanoid(),
      subTab: [],
    }
    const config = {
      component: 'setup/user-preferences',
      key: 'vendor',
      prop_type: 'vendor',
      item: tabObj,
    }
    store.dispatch(`tabSystem/${CRAETE_TABS}`, config)
  }

  const retailVendorTab = (item, redirectUrl, queryParam) => {
    const tabObj = {
      title: item.name,
      icon: mdiAccount,
      to: redirectUrl,
      key: item.uuid,
      id: nanoid(),
      subTab: [],
    }
    const config = {
      component: 'setup/user-preferences',
      key: 'retailVendor',
      prop_type: 'retailVendor',
      item: tabObj,
    }
    store.dispatch(`tabSystem/${CRAETE_TABS}`, config)
  }

  const retailVendorSubTab = (item, redirectUrl, icon = mdiOfficeBuildingOutline) => {
    const payload = {
      title: item.name,
      icon,
      to: redirectUrl,
      key: item.uuid,
      id: nanoid(),
      paramName: item.paramName,
      subTab: [],
    }
    const config = {
      component: 'setup/user-preferences',
      key: 'retailVendor',
      prop_type: 'retailVendor',
      item: payload,
    }
    store.dispatch(`tabSystem/${SUB_TAB_REQUEST}`, config)
  }

  const vendorSubTab = (item, redirectUrl, icon = mdiOfficeBuildingOutline) => {
    const payload = {
      title: item.name,
      icon,
      to: redirectUrl,
      key: item.uuid,
      id: nanoid(),
      paramName: item.paramName,
      subTab: [],
    }
    const config = {
      component: 'setup/user-preferences',
      key: 'vendor',
      prop_type: 'vendor',
      item: payload,
    }
    store.dispatch(`tabSystem/${SUB_TAB_REQUEST}`, config)
  }

  return {
    gotoVendorTab,
    reportSubTab,
    estimationTab,
    customerSubTab,
    reportTab,
    vendorSubTab,
    gotoCrmTab,
    gotoCustomerTab,
    retailVendorTab,
    gotoSubTab,
    retailVendorSubTab,
    gotoEstimationToolTab,
  }
}
