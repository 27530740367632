const security = [
  {
    path: '/retail-vendor',
    name: 'retail-vendors',
    component: () => import('@/views/retail/vendor/VendorList.vue'),
    meta: {
      layout: 'tab',
      app: 'retailVendor',
      appId: 22,
    },
  },
  {
    path: '/retail-vendor/vendors',
    name: 'vendor-retail-layout',
    component: () => import('@/views/retail/vendor/RetailVendorLayout.vue'),
    meta: {
      layout: 'tab',
      app: 'retailVendor',
      appId: 22,
    },
    children: [
      {
        path: ':uuid/view',
        name: 'vendor-retail-view',
        component: () => import('@/views/retail/vendor/VendorView.vue'),
        meta: {
          layout: 'tab',
          app: 'retailVendor',
          appId: 22,
        },
      },
      {
        path: ':uuid/contact',
        name: 'vendor-retail-contact',
        component: () => import('@/components/retail/vendor/ContactView.vue'),
        meta: {
          layout: 'tab',
          app: 'retailVendor',
          appId: 22,
        },
      },

    ],
  },
  {
    path: '/retail-vendor/import/:prop_type',
    name: 'import-retail-vendor',
    component: () => import('@/components/shared-components/ImportExcelData.vue'),
    meta: {
      layout: 'tab',
      app: 'retailVendor',
      appId: 22,
    },
  },
]

export default security
