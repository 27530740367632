import api from '@/plugins/apiService'
import { storeActions, storeGetters, storeMutations } from '@/store/storeConstants'

const {
  FETCH_TABS,
  CRAETE_TABS,
  CANCEL_TAB_REQUEST,
  TAB_POSITION_CHANGE,
  SUB_TAB_REQUEST,
  ACTIVE_TAB_REQUEST,
  CANCEL_SUBTAB_REQUEST,
  SUBTAB_POSITION_CHANGE,
} = storeActions
const {
  GET_CRM_TABS,
  TAB_LOADING,
  GET_ACTIVETAB_ID,
  GET_SUB_TAB,
  GET_ESTIMATION_TABS,
  GET_REPORT_TABS,
  GET_DASHBOARD_TABS,
  GET_VENDOR_TABS,
} = storeGetters
const {
  SET_CRM_TABS,
  SET_TAB_LOADING,
  SET_ACTIVETAB_ID,
  SET_ESTIMATION_TABS,
  SET_REPORT_TABS,
  SET_VENDOR_TABS,
} = storeMutations

const getPersistTabId = () => {
  const consoleState = localStorage.getItem('active-tab')
  if (consoleState) {
    try {
      return JSON.parse(consoleState)
    } catch (error) {
      return []
    }
  }

  return []
}

export default {
  namespaced: true,
  state: {
    crmTabs: [],
    customerTabs: [],
    estimationTab: [],
    reportTab: [],
    dashboardTab: [],
    vendorTab: [],
    retailVendorTab: [],
    loading: false,
    trigerLoading: true,
    activeTabId: getPersistTabId(),
  },
  getters: {
    [GET_CRM_TABS]: state => state.crmTabs,
    GET_CUSTOMER_TABS: state => state.customerTabs,
    GET_RETAIL_VENDOR_TABS: state => state.retailVendorTab,
    [GET_ESTIMATION_TABS]: state => state.estimationTab,
    [GET_REPORT_TABS]: state => state.reportTab,
    [GET_DASHBOARD_TABS]: state => state.dashboardTab,
    [GET_VENDOR_TABS]: state => state.vendorTab,
    [TAB_LOADING]: state => state.loading,
    GET_TRIGGER_LOADING: state => state.trigerLoading,
    [GET_ACTIVETAB_ID]: state => state.activeTabId,
    [GET_SUB_TAB]: state => key => {
      const tabId = state.activeTabId
      let tabArray = []
      if (key === 'report') {
        tabArray = state.reportTab
      } else if (key === 'vendor') {
        tabArray = state.vendorTab
      } else if (key === 'crm') {
        tabArray = state.crmTabs
      } else if (key === 'retailCustomer') {
        tabArray = state.customerTabs
      } else if (key === 'retailVendor') {
        tabArray = state.retailVendorTab
      }
      const index = tabArray.findIndex(opt => {
        return opt.id === tabId
      })
      if (index !== -1) {
        return tabArray[index].subTab
      }

      return []
    },
  },
  mutations: {
    [SET_TAB_LOADING]: (state, payload) => {
      state.loading = payload
    },
    [SET_CRM_TABS]: (state, payload) => {
      state.crmTabs = payload
    },
    SET_CUSTOMER_TABS: (state, payload) => {
      state.customerTabs = payload
    },
    SET_RETAIL_VENDOR_TABS: (state, payload) => {
      state.retailVendorTab = payload
    },
    [SET_REPORT_TABS]: (state, payload) => {
      state.reportTab = payload
    },
    [SET_ESTIMATION_TABS]: (state, payload) => {
      state.estimationTab = payload
    },
    [SET_VENDOR_TABS]: (state, payload) => {
      state.vendorTab = payload
    },
    TRIGGER_LOADING: (state, payload) => {
      state.trigerLoading = payload
    },
    [SET_ACTIVETAB_ID]: (state, payload) => {
      state.activeTabId = payload
    },
  },
  actions: {
    [FETCH_TABS]: ({ commit, getters }, payload) => {
      if (getters.GET_TRIGGER_LOADING) {
        commit(SET_TAB_LOADING, true)
      }

      api
        .findByParams(`${payload.component}`, { params: payload.filter })
        .then(({ data }) => {
          if (data.success) {
            const value = data.data && data.data.length ? data.data[0].value : []

            if (payload.key === 'crm') {
              commit(SET_CRM_TABS, value)
            } else if (payload.key === 'estimation') {
              commit(SET_ESTIMATION_TABS, value)
            } else if (payload.key === 'report') {
              commit(SET_REPORT_TABS, value)
            } else if (payload.key === 'vendor') {
              commit(SET_VENDOR_TABS, value)
            } else if (payload.key === 'retailCustomer') {
              commit('SET_CUSTOMER_TABS', value)
            } else if (payload.key === 'retailVendor') {
              commit('SET_RETAIL_VENDOR_TABS', value)
            }
          }
        })
        .finally(() => {
          commit(SET_TAB_LOADING, false)
        })
    },
    [CRAETE_TABS]: async ({ getters, dispatch }, payload) => {
      const { component, key, item, prop_type } = payload
      let tabArray = []
      if (key === 'crm') {
        tabArray = [...getters[GET_CRM_TABS]]
      } else if (key === 'estimation') {
        tabArray = [...getters[GET_ESTIMATION_TABS]]
      } else if (key === 'report') {
        tabArray = [...getters[GET_REPORT_TABS]]
      } else if (key === 'dashboard') {
        tabArray = [...getters[GET_DASHBOARD_TABS]]
      } else if (key === 'vendor') {
        tabArray = [...getters[GET_VENDOR_TABS]]
      } else if (key === 'retailCustomer') {
        tabArray = [...getters.GET_CUSTOMER_TABS]
      } else if (key === 'retailVendor') {
        tabArray = [...getters.GET_RETAIL_VENDOR_TABS]
      }
      const position = tabArray
        .map(opt => {
          return opt.key
        })
        .indexOf(item.key)
      if (position === -1) {
        const tabPosition = (window.innerWidth - 234) / 200 - 1
        tabArray.splice(tabPosition - 1, 0, item)
      }

      // final object send to api
      const object = {
        key,
        prop_id: 0,
        prop_type,
        value: tabArray,
      }
      api.update(`${component}`, object).finally(() => {
        // fetch all tabs after save directly from store
        const fetchObject = {
          component: 'setup/user-preferences',
          key: payload.key,
          filter: {
            key: payload.key,
          },
        }
        dispatch(FETCH_TABS, fetchObject)
      })
    },
    UPDATE_FETCH_TAB: ({ commit, dispatch }, payload) => {
      api.update('setup/user-preferences', payload).finally(async () => {
        // fetch all tabs after save directly from store
        const fetchObject = {
          component: 'setup/user-preferences',
          key: payload.key,
          filter: {
            key: payload.key,
          },
        }
        commit('TRIGGER_LOADING', false) // when closing tab no loader is required
        await dispatch(FETCH_TABS, fetchObject)
        commit('TRIGGER_LOADING', true)
      })
    },
    [CANCEL_TAB_REQUEST]: ({ commit, getters, dispatch }, payload) => {
      const { key, item, component, prop_type } = payload
      let tabArray = []
      if (key === 'crm') {
        tabArray = [...getters[GET_CRM_TABS]]
      }
      if (key === 'estimation') {
        tabArray = [...getters[GET_ESTIMATION_TABS]]
      }
      if (key === 'report') {
        tabArray = [...getters[GET_REPORT_TABS]]
      }
      if (key === 'dashboard') {
        tabArray = [...getters[GET_DASHBOARD_TABS]]
      }
      if (key === 'vendor') {
        tabArray = [...getters[GET_VENDOR_TABS]]
      } else if (key === 'retailCustomer') {
        tabArray = [...getters.GET_CUSTOMER_TABS]
      } else if (key === 'retailVendor') {
        tabArray = [...getters.GET_RETAIL_VENDOR_TABS]
      }
      const newTabArray = tabArray.filter(opt => {
        return opt.id !== item.id
      })

      if (key === 'crm') {
        commit(SET_CRM_TABS, newTabArray)
      }
      if (key === 'estimation') {
        commit(SET_ESTIMATION_TABS, newTabArray)
      }
      if (key === 'report') {
        commit(SET_REPORT_TABS, newTabArray)
      }
      if (key === 'vendor') {
        commit(SET_VENDOR_TABS, newTabArray)
      }
      if (key === 'retailCustomer') {
        commit('SET_CUSTOMER_TABS', newTabArray)
      } else if (key === 'retailVendor') {
        commit('SET_RETAIL_VENDOR_TABS', newTabArray)
      }

      // final object send to api
      const object = {
        key,
        prop_id: 0,
        prop_type,
        value: newTabArray,
      }
      dispatch('UPDATE_FETCH_TAB', object)
    },
    [TAB_POSITION_CHANGE]: ({ commit, getters, dispatch }, payload) => {
      const { key, item, prop_type } = payload
      let alltabs = []
      if (key === 'crm') {
        alltabs = [...getters[GET_CRM_TABS]]
      } else if (key === 'estimation') {
        alltabs = [...getters[GET_ESTIMATION_TABS]]
      } else if (key === 'report') {
        alltabs = [...getters[GET_REPORT_TABS]]
      } else if (key === 'dashboard') {
        alltabs = [...getters[GET_DASHBOARD_TABS]]
      } else if (key === 'vendor') {
        alltabs = [...getters[GET_VENDOR_TABS]]
      } else if (key === 'retailCustomer') {
        alltabs = [...getters.GET_CUSTOMER_TABS]
      } else if (key === 'retailVendor') {
        alltabs = [...getters.GET_RETAIL_VENDOR_TABS]
      }

      const position = alltabs.findIndex(opt => {
        return opt.key === item.key
      })
      if (position !== -1) {
        const tabPosition = (window.innerWidth - 234) / 200 - 1
        alltabs.splice(position, 1)
        alltabs.splice(tabPosition - 1, 0, item)

        if (key === 'crm') {
          commit(SET_CRM_TABS, alltabs)
        } else if (key === 'estimation') {
          commit(SET_ESTIMATION_TABS, alltabs)
        } else if (key === 'report') {
          commit(SET_REPORT_TABS, alltabs)
        } else if (key === 'vendor') {
          commit(SET_VENDOR_TABS, alltabs)
        } else if (key === 'retailCustomer') {
          commit('SET_CUSTOMER_TABS', alltabs)
        } else if (key === 'retailVendor') {
          commit('SET_RETAIL_VENDOR_TABS', alltabs)
        }

        // final object send to api
        const object = {
          key,
          prop_id: 0,
          prop_type,
          value: alltabs,
        }
        dispatch('UPDATE_FETCH_TAB', object)
      }
    },
    [SUBTAB_POSITION_CHANGE]: ({ commit, getters, dispatch }, payload) => {
      const { key, item, prop_type } = payload
      let tabArray = []
      if (key === 'crm') {
        tabArray = [...getters[GET_CRM_TABS]]
      }

      const tabId = getters[GET_ACTIVETAB_ID]

      const index = tabArray.findIndex(opt => {
        return opt.id === tabId
      })
      if (index !== -1) {
        // get all sub tab array's
        const newSubArr = tabArray[index].subTab
        // find indec of item in subtab array's
        const position = newSubArr.findIndex(opt => {
          return opt.key === item.key
        })
        if (position !== -1) {
          const tabPosition = (window.innerWidth - 380) / 180 - 1
          newSubArr.splice(position, 1)
          newSubArr.splice(tabPosition - 1, 0, item)
          tabArray[index].subTab = newSubArr
          commit(SET_CRM_TABS, tabArray)

          // final object send to api
          const object = {
            key,
            prop_id: 0,
            prop_type,
            value: tabArray,
          }
          dispatch('UPDATE_FETCH_TAB', object)
        }
      }
    },
    [SUB_TAB_REQUEST]: ({ commit, getters, dispatch }, payload) => {
      const { item, key, prop_type } = payload
      let tabArray = []
      if (key === 'crm') {
        tabArray = [...getters[GET_CRM_TABS]]
      }
      if (key === 'report') {
        tabArray = [...getters[GET_REPORT_TABS]]
      }
      if (key === 'estimation') {
        tabArray = [...getters[GET_ESTIMATION_TABS]]
      }
      if (key === 'vendor') {
        tabArray = [...getters[GET_VENDOR_TABS]]
      }
      if (key === 'retailCustomer') {
        tabArray = [...getters.GET_CUSTOMER_TABS]
      }
      if (key === 'retailVendor') {
        tabArray = [...getters.GET_RETAIL_VENDOR_TABS]
      }
      const tabId = getters[GET_ACTIVETAB_ID]

      const index = tabArray.findIndex(opt => {
        return opt.id === tabId
      })

      if (index !== -1) {
        const position = tabArray[index].subTab
          .map(opt => {
            return opt.key
          })
          .indexOf(item.key)

        if (position === -1) {
          // avoid active tab in more buttom dropdown && for crm left side margin is 380
          const length = key === 'crm' ? 380 : 0
          const tabPosition = (window.innerWidth - length) / 180 - 1
          const allSubTab = tabArray[index].subTab
          allSubTab.splice(tabPosition - 1, 0, item)

          tabArray[index].subTab = allSubTab
          if (key === 'crm') {
            commit(SET_CRM_TABS, tabArray)
          }
          if (key === 'report') {
            commit(SET_REPORT_TABS, tabArray)
          }
          if (key === 'estimation') {
            commit(SET_ESTIMATION_TABS, tabArray)
          }
          if (key === 'vendor') {
            commit(SET_VENDOR_TABS, tabArray)
          }
          if (key === 'retailCustomer') {
            commit('SET_CUSTOMER_TABS', tabArray)
          }
          if (key === 'retailVendor') {
            commit('SET_RETAIL_VENDOR_TABS', tabArray)
          }
          // final object send to api
          const object = {
            key,
            prop_id: 0,
            prop_type,
            value: tabArray,
          }
          dispatch('UPDATE_FETCH_TAB', object)
        }
      }
    },
    [ACTIVE_TAB_REQUEST]: ({ commit }, payload) => {
      localStorage.setItem('active-tab', JSON.stringify(payload))
      commit(SET_ACTIVETAB_ID, payload)
    },

    [CANCEL_SUBTAB_REQUEST]: ({ commit, getters, dispatch }, payload) => {
      const { key, item, prop_type } = payload
      let tabArray = []
      if (key === 'crm') {
        tabArray = [...getters[GET_CRM_TABS]]
      }
      if (key === 'report') {
        tabArray = [...getters[GET_REPORT_TABS]]
      }
      if (key === 'vendor') {
        tabArray = [...getters[GET_VENDOR_TABS]]
      }
      if (key === 'retailCustomer') {
        tabArray = [...getters.GET_CUSTOMER_TABS]
      }
      if (key === 'retailVendor') {
        tabArray = [...getters.GET_RETAIL_VENDOR_TABS]
      }
      const tabId = getters[GET_ACTIVETAB_ID]

      const index = tabArray.findIndex(opt => {
        return opt.id === tabId
      })
      if (index !== -1) {
        const newSubArr = tabArray[index].subTab.filter(opt => {
          return opt.id !== item.id
        })
        tabArray[index].subTab = newSubArr
      }

      if (key === 'crm') {
        commit(SET_CRM_TABS, tabArray)
      }
      if (key === 'report') {
        commit(SET_REPORT_TABS, tabArray)
      }
      if (key === 'vendor') {
        commit(SET_VENDOR_TABS, tabArray)
      }
      if (key === 'retailCustomer') {
        commit('SET_CUSTOMER_TABS', tabArray)
      }
      if (key === 'retailVendor') {
        commit('SET_RETAIL_VENDOR_TABS', tabArray)
      }

      // final object send to api
      const object = {
        key,
        prop_id: 0,
        prop_type,
        value: tabArray,
      }
      dispatch('UPDATE_FETCH_TAB', object)
    },
  },
}
