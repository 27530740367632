// eslint-disable-next-line object-curly-newline
import { mdiAccountSupervisorCircle, mdiHomeOutline } from '@mdi/js'

export default [
  {
    title: 'Vendor',
    icon: mdiHomeOutline,
    children: [
      {
        icon: mdiAccountSupervisorCircle,
        title: 'Vendors',
        to: 'retail-vendors',
        moduleKey: 'vendorManagement',
      },
    ],
  },
]
