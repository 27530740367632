import {
  mdiTextBoxOutline,
  mdiFileDocumentOutline,
  mdiHomeOutline,
  mdiProgressWrench,
  mdiFloorPlan,
} from '@mdi/js'

export default [
  {
    subheader: 'Manufacturing',
  },
  // {
  //   title: 'Dashboard',
  //   icon: mdiDesktopMacDashboard,
  //   to: 'goals-dashboard',
  // },
  {
    title: 'Master Bill Of Materials',
    icon: mdiFileDocumentOutline,
    to: 'mbom-list',
  },
  {
    title: 'Bill Of Materials',
    icon: mdiTextBoxOutline,
    to: 'bill-of-materials',
  },
  // {
  //   title: 'Routing',
  //   icon: mdiTextBoxOutline,
  //   to: 'manufacturing-routing',
  // },
  {
    title: 'Work Centers',
    icon: mdiHomeOutline,
    to: 'work-centers',
  },
  {
    title: 'Manufacturing Order',
    icon: mdiProgressWrench,
    to: 'manufacturing-order',
  },
  {
    title: 'Planning',
    icon: mdiFloorPlan,
    to: 'manufacturing-planning',
  },
]
