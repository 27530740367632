import moment from 'moment'
import { isToday } from './index'

export const kFormatter = (num, fraction = 2) => (num > 999 ? `${(num / 1000).toFixed(fraction)}k` : num)

export const title = (value, replacer = ' ') => {
  if (!value) return ''
  const str = value.toString()

  const arr = str.split(replacer)
  const capitalizedArray = []
  arr.forEach(word => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
    capitalizedArray.push(capitalized)
  })

  return capitalizedArray.join(' ')
}

export const avatarText = args => {
  if (!args) return ''
  const nameparts = args.split(' ')
  const firstName = nameparts[0] || 'A'
  const lastName = nameparts[1] || ''

  return firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
}

/**
 * Format and return date in Humanize format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = 'MMM DD, YYYY',
) => {
  if (!value) return value

  return moment(new Date(value)).utc().format(formatting)

}

/**
 * Format and return date in US format
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const DateFormatUS = (
  value,
  formatting = { month: '2-digit', day: '2-digit', year: 'numeric' },
) => {
  if (!value) return value

  return moment(new Date(value)).utc().format('MMM DD, YYYY')

  // return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 * Format and return value in USD
 * Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 * Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 */

export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const currencyFormat = (value, currency = 'USD') => {
  if (value === null || value === undefined) return null

  const formattedCurrency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 4,
  })

  return formattedCurrency.format(value)
}

/**
 * Return short human friendly month representation of date
 * Can also convert date to only time if date is of today (Better UX)
 * @param {String} value date to format
 * @param {Boolean} toTimeForCurrentDay Shall convert to time if day is today/current
 */
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// Strip all the tags from markup and return plain text
export const filterTags = value => value.replace(/<\/?[^>]+(>|$)/g, '')

export const replaceCamelSpaces = text => {
  return text.split(/(?=[A-Z])/).join(' ')
}

export const getFileThumbnail = fileName => {
  if (!fileName) return require('@/assets/images/icons/file-icons/doc_icon.svg')
  const patternFileExtension = /\.([0-9a-z]+)(?:[#]|$)/i
  const fileExtension = fileName.match(patternFileExtension)
  let thumbnail = require('@/assets/images/icons/file-icons/doc_icon.svg')

  if (fileExtension) {
    switch (fileExtension[0]) {
      case '.pdf':
        thumbnail = require('@/assets/images/icons/file-icons/pdf_icon.svg')
        break
      case '.xls':
      case '.xlsx':
        thumbnail = require('@/assets/images/icons/file-icons/xls_icon.svg')
        break
      case '.doc':
      case '.docx':
        thumbnail = require('@/assets/images/icons/file-icons/word_icon.svg')
        break
      case '.ppt':
        thumbnail = require('@/assets/images/icons/file-icons/ppt_icon.svg')
        break
      case '.png':
      case '.jpg':
      case '.jpeg':
      case '.gif':
      case '.svg':
        thumbnail = require('@/assets/images/icons/file-icons/image_icon.svg')
        break
      case '.js':
      case '.json':
        thumbnail = require('@/assets/images/icons/file-icons/json_icon.svg')
        break
      case '.txt':
        thumbnail = require('@/assets/images/icons/file-icons/doc_icon.svg')
        break
      case '.zip':
        thumbnail = require('@/assets/images/icons/file-icons/zip_icon.svg')
        break
      default:
        thumbnail = require('@/assets/images/icons/file-icons/doc_icon.svg')
    }
  }

  return thumbnail
}

export const getFileExtension = fileName => {
  const patternFileExtension = /\.([0-9a-z]+)(?:[#]|$)/i

  const ext = fileName.match(patternFileExtension)

  return ext || []
}

export const removeExtension = fileName => {
  const name = (fileName.split('.').slice(0, -1)).join('.')

  return name || fileName
}

export const bulkDateFormat = (item, keys) => {
  const formattedData = { ...item }
  keys.forEach(key => {
    if (item[key]) {
      const date = moment(new Date(item[key])).utc().format('YYYY-MM-DD')
      formattedData[key] = date
    }
  })

  return formattedData
}

export const dateFormatLocal = (
  value,
  formatting = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  },
) => {
  if (!value) return value

  return new Date(value).toLocaleDateString('en-US', formatting)
}

export const timeFormatLocal = args => {
  return args ? new Date(args).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }) : ''
}

export const dateTimeFormatLocal = (
  value,
  formatting = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
) => {
  if (!value) return value

  return new Date(value).toLocaleDateString('en-US', formatting)
}

export const generateUniqueNumber = prefix => {
  const timestamp = new Date().getTime()

  return `${prefix}${timestamp}`
}

export const printDOMElement = elementId => {
  document.getElementsByTagName('body')[0].style.visibility = 'hidden'
  document.getElementById(elementId).classList.add('print-cnt')
  window.print()
  document.getElementsByTagName('body')[0].style.visibility = 'visible'
  setTimeout(() => {
    document.getElementById(elementId).classList.remove('print-cnt')
  }, 500)
}
