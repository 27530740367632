import {
  mdiDomain,
  mdiTextBoxMultipleOutline,
  mdiBellOutline,
  mdiMonitor,
  mdiMaterialDesign,
  mdiProjectorScreenOutline,
  mdiCalculator,
  mdiOfficeBuildingMarkerOutline,
  mdiToyBrickOutline,
  mdiMathLog,
  mdiIdCard,
} from '@mdi/js'
import { bldonApps } from '@/constants/appConstants'

// const purchaseApps = store.getters[`auth/${storeGetters.GET_LAUNCHER_APP}`]

// console.log('[12] =>', purchaseApps)
const menus = [
  {
    subheader: 'Setup',
  },
  {
    title: 'Company',
    icon: mdiDomain,
    children: [
      {
        title: ' Edit Company Details',
        to: 'setup-company',
      },
      {
        title: 'Organization Departments',
        to: 'setup-orgnization',
      },
      {
        title: 'Define Employee Designations & Levels',
        to: 'setup-designation',
      },
      {
        title: 'Edit Qualifiers',
        to: 'setup-qualifier',
      },
      {
        title: ' License & Payment',
        to: 'setup-license',
      },
    ],
  },
  {
    title: 'Customized Fields',
    icon: mdiTextBoxMultipleOutline,
    children: [
      {
        title: 'Define at Specific Submodule',
        to: 'setup-form-list',
      },
      {
        title: 'Define at Globally',
        to: 'global-form',
      },
      {
        title: 'Define Status Fields',
        to: 'setup-status-list',
      },
      {
        title: 'Custom Workflows',
        to: 'setup-workflow',
      },
      {
        title: 'Module Workflows',
        to: 'setup-module-workflow',
      },
      {
        title: 'Align your Custom Fields',
        to: 'setup-form-alignment',
      },
    ],
  },

  {
    title: 'Setup Alerts & Notifications',
    icon: mdiBellOutline,
    to: 'setup-communication',
  },
  {
    title: 'Crm',
    icon: mdiMonitor,
    appId: [bldonApps.crm.value],
    children: [
      {
        title: 'Define Territory',
        to: 'setup-territory',
      },
    ],
  },
  {
    title: 'Material',
    icon: mdiMaterialDesign,
    appId: [bldonApps.materialManagement.value],
    children: [
      {
        title: ' Define and Edit Material Identifiers',
        to: 'setup-material-identifier',
      },
      {
        title: 'Define and Edit Material Characterstics',
        to: 'setup-material-characteristic',
      },
      {
        title: 'Define and Edit Labor Productivity',
        to: 'setup-labor-productivity',
      },
      {
        title: 'Define and Edit Labor Cost',
        to: 'setup-labor-cost',
      },
      {
        title: 'Define and Edit Labor Cost Template',
        to: 'setup-labor-cost-template',
      },
      {
        title: 'Define and Edit Material unit of Measure',
        to: 'setup-material-measurement',
      },
    ],
  },
  {
    title: 'Project Management',
    icon: mdiProjectorScreenOutline,
    appId: [bldonApps.projectManagement.value],
    children: [
      {
        title: 'Customize your folders for Project Management',
        to: 'setup-folder',
        // appId: [bldonApps.projectManagement.value],
      },
    ],
  },
  {
    title: 'Estimation',
    icon: mdiCalculator,
    appId: [bldonApps.estimation.value],
    children: [
      {
        title: 'Customize your folders for Estimation Project',
        to: 'setup-estimation-folder',
        // appId: [bldonApps.estimation.value],
      },
    ],
  },
  {
    title: 'Inventory Management',
    icon: mdiOfficeBuildingMarkerOutline,
    to: 'inventory-setup',
    appId: [bldonApps.inventoryManagement.value],
  },
  {
    title: 'Retail Inventory Management',
    icon: mdiOfficeBuildingMarkerOutline,
    to: 'retail-inventory-setup',
    // appId: [bldonApps.retailInventory.value],
  },
  {
    title: 'Manufacturing',
    icon: mdiMaterialDesign,
    appId: [bldonApps.materialManagement.value],
    children: [
      {
        title: 'Define Working Hours',
        to: 'setup-manufacturing-work-hours',
      },
    ]
  },
  {
    title: 'Plugins',
    icon: mdiToyBrickOutline,
    // appId: [bldonApps.estimation.value],
    to: 'plugin',
  },
  {
    title: 'Logs',
    icon: mdiMathLog,
    // appId: [bldonApps.estimation.value],
    to: 'setup-logs',
  },
  {
    title: 'Customize IDs',
    icon: mdiIdCard,
    appId: [bldonApps.setup.value],
    to: 'setup-ids',
  },
]

export default menus
