export default [
  {
    path: '/retail-crm/customer',
    name: 'crm-retail-account',
    component: () => import('@/views/retail/customer/customer/CustomersAccount.vue'),
    meta: {
      layout: 'tab',
      title: 'Account',
      app: 'retailCrm',
      appId: 21,
    },
  },
  {
    path: '/retail-crm/customer',
    name: 'account-retail-layout',
    component: () => import('@/views/retail/customer/CustomerLayout.vue'),
    meta: {
      layout: 'tab',
      app: 'retailCrm',
      permission: 'account',
      permissionType: 'v',
      appId: 21,
    },
    children: [
      {
        path: ':uuid/view/:id?',
        name: 'account-retail-view',
        component: () => import('@/views/retail/customer/customer/CustomerView.vue'),
        meta: {
          layout: 'tab',
          app: 'retailCrm',
          permission: 'account',
          permissionType: 'v',
          appId: 21,
        },
      },
      {
        path: ':uuid/contact',
        name: 'customer-contact',
        component: () => import('@/views/retail/customer/contact/ContactView.vue'),
        meta: {
          layout: 'tab',
          app: 'retailCrm',
          permission: 'contact',
          permissionType: 'v',
          appId: 21,
        },
      },
    ],
  },
  {
    path: '/retail-crm/:prop_type/import',
    name: 'import-retail-data',
    component: () => import('@/components/shared-components/ImportExcelData.vue'),
    meta: {
      layout: 'tab',
      app: 'retailCrm',
      appId: 21,
    },
  },
]
